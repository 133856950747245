import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import { humanTime } from './lib/timeFunctions'

const Duration = styled.div`
  margin-bottom: 3px !important;
  span:first-of-type {
    margin-right: 5px;
  }

  span:last-of-type {
    margin-left: 5px;
  }
`

const Label = styled.div`
  margin-bottom: ${(props) => props.type !== 'agency' && '20px'};
`

export const Information = ({ shift }) => {
  const { startAt, endAt, duration, breakDuration, unpaid, location } = shift

  const totalTime = useMemo(() => {
    const currentTime = Number(duration).toFixed(2)
      .split('.')
    const hours = Number(currentTime[0])
    const minutes = Number(((Number(duration) - Number(currentTime[0])) * 60).toFixed(0))
    if (minutes === 0) {
      return `${hours} hrs`
    }
    return `${hours} hrs ${minutes} mins`
  }, [duration])

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} sm={shift.type === 'agency' ? 2.5 : 4}>
        <Label type={shift.type}>Shift Duration</Label>
        <Duration>
          <span className="startAt">{humanTime(startAt)}</span>
          <FontAwesomeIcon icon={faArrowRight} />
          <span className="endAt">{humanTime(endAt)}</span>
        </Duration>
      </Grid>
      <Grid item xs={4} sm={shift.type === 'agency' ? 2.5 : 4}>
        <Label type={shift.type}>Total Paid Hrs</Label>
        <div>{`${totalTime}`}</div>
      </Grid>
      <Grid item xs={4} sm={shift.type === 'agency' ? 2.3 : 4}>
        <Label type={shift.type}>
          Break (
          {unpaid ? 'unpaid' : 'paid'}
          )
        </Label>
        <div>{`${breakDuration} mins`}</div>
      </Grid>
      <Grid item xs={12} sm={shift.type === 'agency' ? 4.7 : 12}>
        <Label type={shift.type}>Location</Label>
        <div>{location}</div>
      </Grid>
    </Grid>
  )
}
