import React, { useState } from 'react'
import { DateDialog } from './DateDialog'

export default {
  title: 'ShiftModal/Dialog/Date',
  component: DateDialog,
}

const Template = () => {
  const [dates, datesHandler] = useState([])
  const [recurring, recurringHandler] = useState(false)

  return (
    <DateDialog
      dates={dates}
      datesHandler={datesHandler}
      recurring={recurring}
      recurringHandler={recurringHandler}
    />
  )
}

export const Main = Template.bind({})
