import React from 'react'
import { Cost } from './Cost'

export default {
  title: 'ShiftModal/Shift/Cost',
  component: Cost,
  argTypes: {
    handler: { action: 'Update Shift' },
  },
}

const Template = ({ handler, duration }) => {
  const nurse = {
    name: 'Nurse',
    id: 9,
  }

  const partnerships = [
    {
      id: 17,
      agency: { name: 'Agency X', id: 16 },
      fee: 0.01,
      feeType: 'percentage',
      departmentPositions: [
        {
          id: 22,
          wage: 15,
          adminPositionList: {
            name: 'General',
            id: 1,
          },
        },
        {
          id: 23,
          wage: 19,
          adminPositionList: nurse,
        },
      ],
    },
  ]
  const shift = { position: nurse, agency: 16 }

  return (
    <Cost
      handler={handler}
      shift={shift}
      duration={duration}
      shiftIndex={0}
      position={nurse}
      partnerships={partnerships}
    />
  )
}

export const Main = Template.bind({})

Main.args = {
  duration: 8,
}
