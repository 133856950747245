import React from 'react'
import { Title } from './Title'

export default {
  title: 'ShiftModal/Dialog/Title',
  comopnent: Title,
  argTypes: {
    closeHandler: { action: 'close' },
  },
}

const Template = (args) => {
  const { closeHandler } = args

  return (
    <Title closeHandler={closeHandler} />
  )
}

export const Main = Template.bind({})
