import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import styled from '@emotion/styled'

export const SimpleButton = styled(Button)(
  ({ width }) => `
  padding-bottom: 5px;
  padding-top: 5px;
  text-transform: capitalize;
  font-weight: bold;
  width: ${width && `${width}px !important`};
  &.MuiButton-containedWarning {
    color: #fff;
  }
  &.MuiButton-outlinedInherit {
    border-color: rgba(113, 114, 116, 0.25);
  }
`,
)

export const StyledHyre = styled(SimpleButton)(
  {
    '&.Mui-disabled': {
      color: '#fff',
      opacity: '0.6',
    },
    '&:hover': {
      backgroundColor: '#00c2be',
    },
  },
  ({ buttonlength, loading, hide }) => {
    const styles = {
      width: 'auto',
      backgroundColor: '#00a7a4',
    }
    switch (buttonlength) {
    case 'submit':
      styles.width = '115px'
      break
    case 'fullWidth':
      styles.width = '100%'
      break
    default:
      styles.width = 'auto'
      break
    }
    if (loading) {
      styles.backgroundColor = '#99ced2'
    }
    if (hide === 'hide') {
      styles.display = 'none'
    }
    return styles
  },
)

const LoadingIcon = styled(CircularProgress)`
  position: absolute;
`

export const SquareIconButton = styled(SimpleButton)`
  min-width: 0;
  padding: 4px 10px;
`

export const HyreButton = ({
  name,
  className,
  buttonlength,
  type,
  startIcon,
  endIcon,
  disabled,
  onClick,
  loading,
  hide,
  component,
}) => (
  <StyledHyre
    className={className}
    buttonlength={buttonlength}
    hide={hide}
    variant="contained"
    color="primary"
    type={type}
    onClick={onClick}
    startIcon={startIcon}
    endIcon={endIcon}
    disabled={disabled || loading}
    component={component}
  >
    {name}
    {loading && <LoadingIcon color="primary" size={24} />}
  </StyledHyre>
)
