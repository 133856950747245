import React from 'react'
import { DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import styled from '@emotion/styled'

const StyledTitle = styled(DialogTitle)`
  display: grid;
  grid-template-columns: auto 30px;
  column-gap: 10px;
  border-bottom: 1px solid #dfe3e8;
  padding: 20px !important;

  .titleTypography {
    font-weight: 500;
    font-size: 20px;
    color: #3e3e4c;
  }

  .closeButton {
    justify-self: center;
    align-self: center;
  }
`

export const Title = ({ closeHandler, quickData }) => {
  const user = quickData && quickData.user
  return (
    <StyledTitle disableTypography>
      <Typography className="titleTypography">
        {`Add Shifts ${user ? `: ${user.firstName} ${user.lastName}` : ''}`}
      </Typography>
      <div className="closeButton">
        <IconButton onClick={closeHandler} size="small">
          <Close />
        </IconButton>
      </div>
    </StyledTitle>
  )
}
