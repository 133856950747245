import React, { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { FooterWrapper } from './UI/Footer'
import { NewButton } from './UI/NewButton'
import { MESSAGE_TABS } from '../../lib/constants'
import { Header } from './UI/Header'
import { User } from './Group/User'
import { ConfirmDialog } from './components/ConfirmDialog'
import { AbsoluteLoading } from '../Icons/Loading'
import { ImportantDialog } from './components/ImportantDialog'
import { SearchUser } from './Users/SearchUser'
import { UserSelected } from './Users/UserSelected'

import GetGroupMembers from './graphql/queries/getGroupMembers.gql'
import AddParticipants from './graphql/mutations/addParticipants.gql'
import RemoveParticipants from './graphql/mutations/removeParticipants.gql'

const ModalBody = styled.div(({ selected }) => `
  height: calc(100vh - ${selected ? '160px' : '135px'}) !important;
`)

const SubTitle = styled.div`
  font-size: 16px;
  margin: 23px 0;
`

export const GroupUser = ({
  switchProfile,
  showTagManagement,
  organizationId,
  isOrganizer,
  selectedFacility,
  selectUserFac,
  departmentId,
  setPanel,
  setThread,
  thread,
  setGroupName,
  groupName,
  setNotifyMsg,
}) => {
  const facId = useMemo(() => selectedFacility || selectUserFac, [
    selectUserFac,
    selectedFacility,
  ])
  const [editing, setEditing] = useState(false)
  const [selected, setSelected] = useState([])
  const [members, setMembers] = useState([])
  const [loadMember, setLoadMember] = useState(true)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openPermission, setOpenPermission] = useState(false)

  const handleSelect = ({ target }) => {
    if (target.checked) {
      setSelected(members)
    } else {
      setSelected([])
    }
  }

  const selectAll = useMemo(() => selected.length === members.length, [
    selected,
    members,
  ])

  useQuery(GetGroupMembers, {
    fetchPolicy: 'cache-and-network',
    variables: {
      roomId: thread.roomId,
    },
    onCompleted: ({ currentUser }) => {
      const groupMembers = currentUser.availableRooms.items[0].chatParticipants.map(
        (part) => part.user,
      )
      setThread((prev) => ({ ...prev, users: groupMembers }))
      setSelected(groupMembers)
      if (groupMembers.length === 0) {
        setEditing(true)
      }
      setLoadMember(false)
    },
  })

  const [addParticipants, { loading }] = useMutation(AddParticipants)

  const [removeParticipants, { loading: loadRemove }] = useMutation(
    RemoveParticipants,
  )

  const userNotInGroup = useMemo(
    () => members && members.length > 0 && members.filter((user) => !thread.users.some((u) => u.id === user.id)),
    [members, thread.users],
  )

  const removedUsers = useMemo(
    () => thread.users.filter((user) => !selected.some((u) => u.id === user.id)),
    [selected, thread],
  )
  const addedUsers = useMemo(
    () => selected.filter((user) => !thread.users.some((u) => u.id === user.id)),
    [selected, thread],
  )

  const confirmText = useMemo(() => {
    let text = ''
    if (removedUsers.length > 0 && addedUsers.length > 0) {
      text = `Are you sure you want to add ${addedUsers
        .map((user) => user.fullName)
        .join(', ')} to the group and remove ${removedUsers
        .map((user) => user.fullName)
        .join(', ')} from the group?`
    } else if (removedUsers.length > 0) {
      text = `Are you sure you want to remove ${removedUsers
        .map((user) => user.fullName)
        .join(', ')} from the group?`
    } else if (addedUsers.length > 0) {
      text = `Are you sure you want to add ${addedUsers
        .map((user) => user.fullName)
        .join(', ')} to the group?`
    }
    return text
  }, [addedUsers, removedUsers])

  const btnText = useMemo(() => {
    if (editing) {
      if (thread.users.length > 0 && selected.length > 0) {
        return 'Confirm'
      }
      return 'Add Users'
    }
    return 'Edit Users'
  }, [editing, selected, thread])

  const handleBack = () => {
    setPanel(MESSAGE_TABS.GROUPS)
  }

  const handleAddParticipants = () => {
    addParticipants({
      variables: {
        roomId: thread.roomId,
        participantIds: selected.map((sel) => sel.id),
        canViewHistory: true,
      },
    }).then(() => {
      setPanel(MESSAGE_TABS.THREAD)
      setThread((prev) => ({ ...prev, users: selected }))
      setGroupName('')
    })
  }

  const handleModifyParticipants = async () => {
    if (addedUsers.length > 0) {
      setOpenConfirm(false)
      setOpenPermission(true)
    } else if (removedUsers.length > 0) {
      removeParticipants({
        variables: {
          roomId: thread.roomId,
          participantIds: removedUsers.map((sel) => sel.id),
        },
      }).then(() => {
        setPanel(MESSAGE_TABS.THREAD)
        setThread((prev) => ({ ...prev, users: selected }))
        setGroupName('')
      })
    }
  }

  const handleNewMemberPermission = async (canViewHistory) => {
    try {
      await Promise.all([
        addParticipants({
          variables: {
            roomId: thread.roomId,
            participantIds: addedUsers.map((sel) => sel.id),
            canViewHistory,
          },
        }),
        removedUsers.length > 0
          && removeParticipants({
            variables: {
              roomId: thread.roomId,
              participantIds: removedUsers.map((sel) => sel.id),
            },
          }),
      ])
      setPanel(MESSAGE_TABS.THREAD)
      setThread((prev) => ({ ...prev, users: selected }))
      setGroupName('')
    } catch (error) {
      setNotifyMsg({ message: 'Something went wrong!', type: 'error' })
    }
  }

  const onClick = () => {
    if (thread.users.length === 0) {
      handleAddParticipants()
    } else if (editing && (removedUsers.length > 0 || addedUsers.length > 0)) {
      setOpenConfirm(true)
    } else {
      setEditing(!editing)
    }
  }

  return (
    <>
      <div className="modal-header">
        <Header
          tab={MESSAGE_TABS.GROUP_USER}
          name={groupName}
          setPanel={handleBack}
        />
      </div>
      <ModalBody className="modal-body" selected={editing && selected.length > 0}>
        {editing && (
          <SearchUser
            departmentId={switchProfile ? facId : departmentId}
            organizationId={organizationId}
            isOrganizer={isOrganizer}
            showTagManagement={showTagManagement}
            selectAll={selectAll}
            handleSelect={handleSelect}
            setMembers={setMembers}
          />
        )}
        {thread.users.length > 0 && editing ? (
          <>
            <SubTitle>Users in Group</SubTitle>
            <div>
              {thread.users.length > 0
                && thread.users.map((user) => (
                  <User
                    key={user.id}
                    editing
                    selected={selected}
                    setSelected={setSelected}
                    user={user}
                  />
                ))}
            </div>
            <SubTitle>Users not in Group</SubTitle>
            <div>
              {userNotInGroup.length > 0
                && userNotInGroup.map((user) => (
                  <User
                    key={user.id}
                    editing
                    selected={selected}
                    setSelected={setSelected}
                    user={user}
                  />
                ))}
            </div>
          </>
        ) : (
          <div>
            {thread.users.length > 0
              ? thread.users.map((user) => (
                <User
                  key={user.id}
                  selected={selected}
                  setSelected={setSelected}
                  user={user}
                />
              ))
              : members.length > 0
                && members.map((user) => (
                  <User key={user.id} editing selected={selected} setSelected={setSelected} user={user} />
                ))}
          </div>
        )}
        {loadMember && <AbsoluteLoading />}
        {openConfirm && (
          <ConfirmDialog
            open={openConfirm}
            disabled={loading || loadRemove}
            onSubmit={handleModifyParticipants}
            onClose={() => setOpenConfirm(false)}
            buttonName="Yes"
          >
            {confirmText}
          </ConfirmDialog>
        )}
        {openPermission && (
          <ImportantDialog
            open={openPermission}
            onConfirm={() => handleNewMemberPermission(true)}
            onCancel={() => handleNewMemberPermission(false)}
            confirmText="Yes"
            cancelText="No"
            content={`Do you want to allow ${addedUsers
              .map((user) => user.fullName)
              .join(', ')} to be able to see the previous chat?`}
          />
        )}
      </ModalBody>
      {editing && <UserSelected selected={selected} />}
      <div className="modal-footer">
        <FooterWrapper>
          <NewButton
            text={btnText}
            disabled={selected.length === 0 || loading}
            handler={onClick}
          />
        </FooterWrapper>
      </div>
    </>
  )
}
