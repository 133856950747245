import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '../components/Avatar'
import { CHAT_ROOM_TYPE, MESSAGE_TABS, MESSAGE_TYPE, capitalizeFirstLetter } from '../../../lib/constants'

dayjs.extend(relativeTime)
const useStyles = makeStyles({
  unread: { fontWeight: 'bold' },
  read: { fontWeight: 'regular' },
  wrap: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
  threadContainer: {
    marginLeft: '10px',
    paddingLeft: '5px',
    paddingBottom: '20px',
    borderBottom: '1px solid #E0E0E0',
  },

  threadLabel: {
    fontSize: '16px',
  },

  badge: {
    backgroundColor: '#00A7A4',
  },
})

export const ThreadMessage = ({ userId, setThread, setPanel, room }) => {
  const classes = useStyles()
  const message = room.chatMessages.items.length > 0 ? room.chatMessages.items[0] : []
  const participants = room.chatParticipants
  const sentAt = message.createdAt
  const isReceived = room.chatMessages.items.length > 0 && room.chatMessages.items[0].user.id !== userId
  const unread = isReceived && !message.isViewed

  const handler = () => {
    setThread({ type: room.roomType, roomId: room.id, users: participants.map((user) => user.user) })
    setPanel(MESSAGE_TABS.THREAD)
  }

  const name = useMemo(() => {
    if (room.roomType === CHAT_ROOM_TYPE.GROUP) {
      return room.name
    }
    const num = participants.length
    const listName = participants.map((user) => user.user.fullName)
    if (num <= 2) {
      return listName.join(', ')
    }
    return `${listName.slice(0, 2).join(', ')} +${num - 2}`
  }, [participants, room])

  const classNames = ['col-sx-10 col-sm-10 col-md-10', classes.wrap, unread ? classes.unread : classes.read].join(' ')

  return (
    <div className={` ${classes.threadContainer} row`} tabIndex={0} role="link" onKeyDown={() => {}} onClick={handler}>
      <div className="col-sx-12 col-sm-12 col-md-12">
        <div className="row">
          <div className="col-sx-2 col-sm-2 col-md-2 margin-top-10">
            <Avatar thumbnail={participants.length > 0 && participants[0].user.avatar.thumb} name={room.name} />
          </div>
          <div className="col-sx-10 col-sm-10 col-md-10">
            <div className="row">
              <div className={`col-sx-6 col-sm-6 col-md-6 padding-vert-10 ${classes.threadLabel}`}>{name}</div>
              <div className="col-sx-6 col-sm-6 col-md-6 padding-vert-10 text-right">{dayjs(sentAt).fromNow()}</div>
            </div>
            <div className="row">
              <div className={classNames}>
                {message.messageType === MESSAGE_TYPE.SYSTEM ? capitalizeFirstLetter(message.content) : message.content}
              </div>
              <div className="col-sx-2 col-sm-2 col-md-2">
                {room.totalUnread > 0 && <span className={`badge ${classes.badge}`}>{room.totalUnread}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
