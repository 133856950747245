import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled'
import { Label } from './Label'
import { Field } from './Field'

const Styled = styled.div`
  display: grid
`

export const NotesDialog = ({ notes, notesHandler }) => {
  const handler = (event) => {
    notesHandler(event.target.value)
  }

  return (
    <Styled>
      <Label>Shift Notes</Label>
      <FormControl>
        <Field
          id="shiftNotes"
          placeholder="Optional"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faFileAlt} className="icon" />
              </InputAdornment>
            ),
          }}
          value={notes}
          onChange={handler}
        />
      </FormControl>
    </Styled>
  )
}
