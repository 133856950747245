import React from 'react'

import { Scheduling } from './Scheduling'

export default {
  title: 'Onboarding/Scheduling',
  component: Scheduling,
}

// eslint-disable-next-line react/jsx-props-no-spreading
const Template = (args) => <Scheduling {...args} />

export const Main = Template.bind({})
Main.args = {

}
