import React from 'react'

const SendEmail = ({ className, onClick, fill }) => (
  <svg
    className={className}
    width="26"
    height="26"
    fill={fill}
    onClick={onClick}
    version="1.1"
    viewBox="0 0 752 752"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`m186.93 487.63 124.96-9.6172 67.938 106.86c1.9805 2.9961 5.4766 4.625 9.043 4.2109 3.3594-0.56641 
          6.1914-2.832 7.4766-5.9922l46.527-80.008 120.93 26.441-0.003906 0.003906c2.8242 0.61328 
          5.7734-0.12109 7.9727-1.9922 2.2031-1.8711 3.4062-4.6602 
          3.2578-7.5469l-18.148-348.32c-0.18359-3.5156-2.3438-6.625-5.5781-8.0234-3.2344-1.3984-6.9805-0.83984-9.668 
          1.4375l-361.41 306.23c-3.0625 2.5977-4.1055 6.875-2.5781 10.59 1.5273 3.7148 5.2734 6.0273 9.2812 
          5.7188zm312.08-240.2-116.45 230.62c-0.60938 1.207-0.94531 2.5273-0.99219 3.8789l-2.2031 67.676-51.312-80.707z
          `}
    />
  </svg>
)

export default SendEmail
