import React, { useEffect, useState } from 'react'
import { ApolloClient, InMemoryCache, createHttpLink as CreateHttpLink, ApolloProvider } from '@apollo/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { setContext } from '@apollo/client/link/context'
import { useCookies } from 'react-cookie'
import ShiftModal from './ShiftModal/ShiftModal'

dayjs.extend(utc)
const CreateShift = ({ isAgency, isAgencyShiftEnabled, inHouseStaffEnabled, showTagManagement }) => {
  const [cookies] = useCookies(['jwt_token'])
  const [openModal, setOpenModal] = useState(false)
  const [quickData, setQuickData] = useState(null)

  const webHeader = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: cookies.jwt_token ? `Bearer ${cookies.jwt_token}` : '',
    },
  }))

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: webHeader.concat(
      new CreateHttpLink({
        uri: '/apisite/v1/graphql',
      }),
    ),
  })

  useEffect(
    () => window.addEventListener('openShiftCreation', (e) => {
      setOpenModal(true)
      setQuickData(e.detail)
    }),
    [],
  )

  return (
    <ApolloProvider client={apolloClient}>
      {openModal && (
        <ShiftModal
          open={openModal}
          setOpen={setOpenModal}
          quickData={quickData}
          setQuickData={setQuickData}
          isAgency={isAgency}
          isAgencyShiftEnabled={isAgencyShiftEnabled}
          inHouseStaffEnabled={inHouseStaffEnabled}
          showTagManagement={showTagManagement}
        />
      )}
    </ApolloProvider>
  )
}

export default CreateShift
