import React from 'react'
import { Shift } from './Shift'

export default {
  title: 'ShiftModal/Shift',
  component: Shift,
  argTypes: {
    removeHandler: { action: 'RemoveHandler' },
    updateHandler: { action: 'UpdateHandler' },
  },
}

const Template = ({
  name,
  qty,
  startAt,
  endAt,
  breakDuration,
  location,
  date1,
  date2,
  notes,
  updateHandler,
  removeHandler,
}) => {
  const position = {
    id: 1,
    name,
  }

  const shift = {
    position,
    qty,
    dates: [
      date1,
      date2,
    ],
    notes,
    startAt,
    endAt,
    breakDuration,
    location,
  }

  const partnerships = [
    {
      id: 17,
      agency: { name: 'Agency X', id: 16 },
      fee: 0.01,
      feeType: 'percentage',
      departmentPositions: [
        {
          id: 22,
          wage: 15,
          adminPositionList: {
            name: 'General',
            id: 1,
          },
        },
        {
          id: 23,
          wage: 19,
          adminPositionList: position,
        },
      ],
    },
  ]

  return (
    <Shift
      shift={shift}
      shiftIndex={0}
      removeHandler={removeHandler}
      updateHandler={updateHandler}
      partnerships={partnerships}
    />
  )
}

export const Main = Template.bind({})

Main.args = {
  name: 'Dietary Aide',
  qty: 2,
  startAt: '09:00',
  endAt: '17:00',
  breakDuration: 15,
  notes: 'This is a sample note',
  location: '7900 McLaughlin Rd, Missisauga, ON L5B 5C7',
  date1: '2021-03-21',
  date2: '2021-03-22',
}
