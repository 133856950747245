import React from 'react'

const Search = ({ className }) => (
  <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6477 11.9057C10.3119 12.944 8.63073 13.4338 6.94662 13.2754C5.26251 13.117 3.70205 12.3224 2.58297
        11.0533C1.46389 9.78421 0.870337 8.13609 0.923169 6.44452C0.976 4.75295 1.67124 3.14512 2.86734
        1.94841C4.06343 0.751707 5.67044 0.0561073 7.36114 0.00324857C9.05185 -0.0496101 10.6991 0.544247
        11.9676 1.6639C13.236 2.78355 14.0302 4.34482 14.1885 6.02979C14.3468 7.71476 13.8573 9.39674 12.8196
        10.7333L16.6748 14.5761C17.0012 14.9014 17.0017 15.4298 16.6759 15.7557C16.3502 16.0816 15.8217 16.0814
        15.4963 15.7552L11.656 11.9057H11.6477ZM7.57525 11.6396C8.23011 11.6396 8.87855 11.5106 9.48356 11.2598C10.0886
        11.0091 10.6383 10.6416 11.1014 10.1783C11.5644 9.71503 11.9317 9.16503 12.1823 8.55971C12.4329 7.95439 12.5619
        7.30561 12.5619 6.65042C12.5619 5.99522 12.4329 5.34645 12.1823 4.74113C11.9317 4.13581 11.5644 3.5858 11.1014
        3.12251C10.6383 2.65922 10.0886 2.29172 9.48356 2.04099C8.87855 1.79025 8.23011 1.6612 7.57525 1.6612C6.2527
        1.6612 4.98433 2.18685 4.04915 3.12251C3.11397 4.05817 2.58859 5.3272 2.58859 6.65042C2.58859 7.97364 3.11397
        9.24267 4.04915 10.1783C4.98433 11.114 6.2527 11.6396 7.57525 11.6396Z"
      fill="#454F5B"
    />
  </svg>
)

export default Search
