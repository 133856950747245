import React from 'react'

import { Box, Portal } from '@material-ui/core'
import { Scheduling } from './Scheduling'

export const BusinessTooltip = () => (
  <Portal>
    <Box sx={{
      maxWidth: 300,
      position: 'fixed',
      bottom: 0,
      left: 0,
      zIndex: 999,
    }}
    >
      <Scheduling />
    </Box>
  </Portal>
)
