import React from 'react'
import styled from '@emotion/styled'
import { Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { HyreButton } from './StyledButton'

const StyledDialog = styled(Dialog)(
  ({ theme }) => `
  .MuiPaper-root {
    width: 425px;
    border-radius: 20px;
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
    display: block;
    border: 1px solid #ddd;
    box-shadow: none;
    font-family: 'Roboto';
    line-height: 20px;
    .MuiDialogContent-root {
      padding-top: 50px;
    }
  }
`,
)

const ConfirmContent = styled(DialogContent)`
  font-size: 14px;
  padding: 34px 28px 14px 28px;
  display: relative;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`

const ConfirmActions = styled(DialogActions)`
  display: block;
  text-align: center;
`

const StyledSubmit = styled(HyreButton)`
  font-size: 15px;
  text-transform: uppercase;
`

export const ConfirmDialog = ({ open, onClose, onSubmit, disabled, children, buttonName }) => (
  <StyledDialog open={open} onClose={onClose} maxWidth={false}>
    <ConfirmContent>
      <CloseButton onClick={onClose} size="small">
        <CloseIcon fontSize="large" />
      </CloseButton>
      {children}
    </ConfirmContent>
    <ConfirmActions>
      <StyledSubmit disabled={disabled} name={buttonName} onClick={onSubmit} />
    </ConfirmActions>
  </StyledDialog>
)
