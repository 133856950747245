import React from 'react'
import { Information } from './Information'

export default {
  title: 'ShiftModal/Shift/Information',
  component: Information,
}

const Template = (args) => {
  const shift = {
    startAt: args.startAt,
    endAt: args.endAt,
    breakDuration: args.breakDuration,
    location: args.location,
  }

  return (
    <Information shift={shift} />
  )
}

export const Main = Template.bind({})

Main.args = {
  startAt: '09:00',
  endAt: '17:00',
  breakDuration: 15,
  location: 'Alderaan Space Port',
}
