/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Autocomplete, TextField } from '@material-ui/core'
import styled from '@emotion/styled'

import UpdateCurrentDepartment from './UpdateDepartmentId.gql'
import GetDepartment from './GetDepartment.gql'

const StyledAutocomplete = styled(Autocomplete)(
  ({ theme }) => `
  ${theme.breakpoints.up('xs')} {
    .MuiOutlinedInput-root {
      width: 200px;
      padding-bottom: 5px !important;
      padding-top: 5px !important;
    }
  }
`,
)

const grabParents = (nodes) => nodes.filter((x) => !x.parentId)
const buildTree = ({ parents, rest }) => parents.map((x) => [x, rest.filter((y) => y.parentId === x.id)])
const sortedOptions = (options) => {
  const parents = grabParents(options)
  const rest = options.filter((x) => !parents.map((y) => y.id).includes(x.id))
  const tree = buildTree({ parents, rest })
  return tree.flat(Infinity)
}

export const NavToggle = ({ setCookie, currentDepartmentId }) => {
  const [user, setUser] = useState(null)
  const [options, setOptions] = useState([])
  const [permitted, setPermitted] = useState([])
  const [value, setValue] = useState(null)

  const [updateDepartmentId] = useMutation(UpdateCurrentDepartment)
  useQuery(GetDepartment, {
    onCompleted: ({ currentUser }) => {
      setUser(currentUser)
      const opts = sortedOptions(currentUser.organizer.partOfDepartments)
      setPermitted(currentUser.organizer.permittedDepartments)
      setOptions(opts)
      setValue(opts.find((o) => o.id === currentDepartmentId()))
    },
  })

  const handleChange = async (target) => {
    setCookie('current_department_id', target.id, { path: '/' })
    const { pathname } = window.location
    const paths = pathname.split('/')
    if (paths[1] === 'department') {
      paths[2] = target.id
    }
    window.location.pathname = paths.join('/')
  }

  if (!user) {
    return null
  }

  return (
    <StyledAutocomplete
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue)
        handleChange(newValue)
        updateDepartmentId({ variables: { departmentId: newValue.id } })
      }}
      id="country-select-demo"
      size="small"
      options={options}
      getOptionDisabled={(option) => !permitted.map((d) => d.id).includes(option.id)}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <span key={`${option.id}-span`}>
          {option.parentId && (
            <ul key={`${option.parentId}-${option.id}`}>
              <li {...props} key={option.id}>
                {option.name}
              </li>
            </ul>
          )}
          {!option.parentId && (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
        </span>
      )}
      renderInput={(params) => <TextField {...params} label="Select facility" />}
    />
  )
}
