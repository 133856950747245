import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import { Message } from './Thread/Message'
import { MessageBox } from './Thread/MessageBox'
import {
  CHAT_ROOM_TYPE,
  MESSAGE_STATUS,
  MESSAGE_TABS,
  capitalizeFirstLetter,
  inRange,
} from '../../lib/constants'
import { toDate } from '../helpers/time'
import { Header } from './UI/Header'

import GetMessages from './graphql/queries/getMessages.gql'
import MarkRead from './graphql/mutations/markRead.gql'

const ModalBody = styled.div`
  height: calc(100vh - 165px) !important;
`

const ModalFooter = styled.div`
  padding-bottom: 20px !important;
`

const Placeholder = styled.div`
  position: absolute;
  bottom: 0;
  right: 15px;
  color: rgba(0, 0, 0, 0.5);
`

const Date = styled.div`
  text-align: center;
  margin-bottom: 8px;
`

const FirstMessage = styled.div`
  color: #828282;
  width: 330px;
  margin: 30px auto;
  &.${CHAT_ROOM_TYPE.DIRECT} {
    width: 170px;
  }
`

const ExpandBtn = styled(Button)`
  padding: 0;
  text-transform: none;
  margin-bottom: 5px;
  height: 12px;
`

const MessageDate = ({ messages, thread, userId, markRead }) => {
  const messageDate = {}
  messages.map((item) => {
    messageDate[toDate(item.createdAt)] = []
    return messageDate
  })
  const arrayMessages = [...messages]
    .sort((f, s) => dayjs(f.createdAt) - dayjs(s.createdAt))
    .reduce((_c, c) => {
      messageDate[toDate(c.createdAt)].push(c)
      return messageDate
    }, Object.create(null))

  return Object.entries(arrayMessages).map(([key, value], index) => (
    <div key={key}>
      <Date>{key}</Date>
      <div>
        {value.map((message, i) => (
          <Message
            roomId={thread.roomId}
            userId={userId}
            thread={thread}
            key={message.id}
            message={message}
            markRead={markRead}
            isLast={
              index === Object.entries(arrayMessages).length - 1
              && i === value.length - 1
            }
          />
        ))}
      </div>
    </div>
  ))
}

export const Thread = ({
  isOrganizer,
  userId,
  thread,
  updateObj,
  setPanel,
  setThread,
  setNotifyMsg,
}) => {
  const [limit, setLimit] = useState(20)
  const [totalMessages, setTotalMessages] = useState(0)
  const [data, setData] = useState([])
  const [isBottom, setIsBottom] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const messagesRef = useRef(null)

  const { loading } = useQuery(GetMessages, {
    fetchPolicy: 'cache-and-network',
    variables: {
      roomId: thread.roomId,
      participantIds: !thread.roomId
        ? thread.users.map((user) => user.id)
        : null,
      limit,
      departmentId: thread.departmentId,
    },
    onCompleted: ({ currentUser }) => {
      if (currentUser.availableRooms.items.length > 0) {
        const { items } = currentUser.availableRooms
        setThread((prev) => ({
          ...prev,
          roomId: items[0].id,
          departmentId: thread.departmentId,
        }))
        setTotalMessages(items[0].chatMessages.pagination.totalCount)
        const newMessage = [
          ...items[0].chatMessages.items.map((item) => ({
            ...item,
            status: item.readAt ? MESSAGE_STATUS.READ : MESSAGE_STATUS.RECEIVED,
          })),
        ].reverse()
        setData(newMessage)

        if (scrollPosition !== 0) {
          setTimeout(() => {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight - scrollPosition
          }, 200)
        }
      }
    },
  })

  const [markRead] = useMutation(MarkRead)

  useEffect(() => {
    if (
      updateObj
      && thread.roomId === updateObj.id
      && updateObj.chatMessages.items.length > 0
    ) {
      setData((prev) => {
        const listMessage = [...prev]
        const index = listMessage.findIndex(
          (message) => message.id === updateObj.chatMessages.items[0].id,
        )
        if (index === -1) {
          listMessage.push({
            ...updateObj.chatMessages.items[0],
            status: updateObj.chatMessages.items[0].readAt
              ? MESSAGE_STATUS.READ
              : MESSAGE_STATUS.RECEIVED,
          })
          markRead({ variables: { roomId: thread.roomId } })
        } else {
          listMessage.splice(index, 1)
          listMessage.push({
            ...updateObj.chatMessages.items[0],
            status: updateObj.chatMessages.items[0].readAt
              ? MESSAGE_STATUS.READ
              : MESSAGE_STATUS.RECEIVED,
          })
        }
        setIsBottom(false)
        return listMessage
      })
      setTotalMessages(updateObj.chatMessages.pagination.totalCount)
    }
  }, [updateObj, thread, markRead])

  useEffect(() => {
    const { current } = messagesRef
    const loadMore = (e) => {
      const el = e.target
      if (
        inRange(
          el.scrollTop + el.clientHeight,
          el.scrollHeight - 50,
          el.scrollHeight + 50,
        )
      ) {
        setIsBottom(true)
      }
      if (
        isBottom
        && !loading
        && el.scrollTop <= 120
        && limit < totalMessages
      ) {
        setScrollPosition(el.scrollHeight)
        setLimit((prev) => prev + 10)
      }
    }
    if (!isBottom) {
      current.scrollTop = current.scrollHeight
    }
    if (data && data.length < totalMessages) {
      current.addEventListener('scroll', loadMore)
    }
    return () => {
      current.removeEventListener('scroll', loadMore)
    }
  }, [data, isBottom, loading, totalMessages, limit])

  useEffect(() => {
    const handleScrollHeightChange = () => {
      if (messagesRef.current && scrollPosition !== 0) {
        messagesRef.current.scrollTop = messagesRef.current.scrollHeight - scrollPosition
      }
    }

    // Initial scrollHeight
    handleScrollHeightChange()

    // Listen for changes in scrollHeight
    const observer = new ResizeObserver(handleScrollHeightChange)
    if (messagesRef.current) {
      observer.observe(messagesRef.current)
    }

    // Cleanup
    return () => {
      observer.disconnect()
    }
  }, [scrollPosition])

  const firstMessage = useMemo(() => {
    let message = null
    if (thread.type === CHAT_ROOM_TYPE.DIRECT) {
      message = `This is the beginning of your conversation with ${thread.users[0].firstName}`
    } else {
      const msgType = thread.type === CHAT_ROOM_TYPE.BROADCAST ? 'broadcast' : 'group'
      const lstU = thread.users.map((user) => user.fullName).join(', ')
      const bcMsg = `You are sending out a ${msgType} message to the following ${thread.users.length} users:`
      message = (
        <div>
          <div>{bcMsg}</div>
          <span>
            {capitalizeFirstLetter(
              isExpanded
                ? lstU
                : `${lstU.slice(0, 150)}${lstU.length > 150 ? '...' : ''}`,
            )}
          </span>
          {lstU.length > 150 && (
            <ExpandBtn
              variant="text"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? 'See less' : 'See more'}
            </ExpandBtn>
          )}
        </div>
      )
    }
    return message
  }, [isExpanded, thread])

  const showNewMessage = useMemo(
    () => _.isEmpty(data)
      || dayjs(!_.isEmpty(data) && data[0].createdAt).isAfter('2024-05-15'),
    [data],
  )

  return (
    <>
      <div className="modal-header">
        <Header
          tab={MESSAGE_TABS.THREAD}
          isOrganizer={isOrganizer}
          users={thread.users}
          type={thread.type}
          setPanel={setPanel}
        />
      </div>
      <ModalBody className="modal-body" ref={messagesRef}>
        {showNewMessage ? (
          <>
            {!loading && data.length === totalMessages && (
              <FirstMessage className={`text-center ${thread.type}`}>
                {firstMessage}
              </FirstMessage>
            )}
          </>
        ) : (
          <>
            {thread.type === CHAT_ROOM_TYPE.BROADCAST && (
              <div className="text-center">{`You created a broadcast list with ${thread.users.length} staff`}</div>
            )}
          </>
        )}
        {data.length > 0 && (
          <MessageDate
            messages={data}
            thread={thread}
            userId={userId}
            markRead={markRead}
          />
        )}
      </ModalBody>
      <ModalFooter className="modal-footer">
        <MessageBox
          setNotifyMsg={setNotifyMsg}
          thread={thread}
          setData={setData}
          totalMessages={totalMessages}
          setTotalMessages={setTotalMessages}
          messagesRef={messagesRef}
        />
        <Placeholder>
          <div>
            <strong>Shift + Return </strong>
            to add a new line
          </div>
        </Placeholder>
      </ModalFooter>
    </>
  )
}
