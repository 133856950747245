import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  links: {
    color: '#CCC',
    fontSize: '16px',
    margin: '3px 30px 3px 0',
    border: '0',
    padding: '0',
    backgroundColor: 'transparent',

    '&:hover': {
      color: '#333',
    },
  },

  disabled: {
    '&:hover': {
      color: '#CCC',
    },
  },

  selected: {
    color: '#333',
  },
})

export const HeaderLink = ({ className, disabled, label, id, panel, setPanel = () => null }) => {
  const classes = useStyles()

  const isSelected = useMemo(() => panel === id, [panel, id])
  const handler = () => (disabled ? null : setPanel(id))

  return (
    <button
      type="button"
      disabled={disabled}
      className={`${className} ${classes.links} ${disabled ? classes.disabled : ''} ${
        isSelected ? classes.selected : ''
      }`}
      onClick={handler}
    >
      {label}
    </button>
  )
}
