import React from 'react'
import {
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import styled from '@emotion/styled'
import { useFormContext } from 'react-hook-form'
import { zeroPad } from '../../lib/constants'
import { formattedCurrencyTransaction } from '../helpers/currency'
import { ArrowDropUp } from '../Icons/ArrowDropUp'
import { ArrowDropDown } from '../Icons/ArrowDropDown'

const Wrapper = styled(Grid)`
  padding: 24px 24px 0;
`

const LabelField = styled(FormLabel)`
  color: #000;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 15px;
  span {
    font-weight: normal;
    text-transform: lowercase;
    font-style: italic;
  }
`

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    padding: 7px 12px;
    font-size: 15px;
    &.Mui-disabled {
      -webkit-text-fill-color: #919eab;
    }
  }
`

const StyledButton = styled(Button)`
  color: #ffffff;
  background-color: #00a7a4;
  border: 1px solid #007f79;
  font-weight: 600;
  text-transform: capitalize;
  :hover {
    background-color: #00bfb1;
    border: 1px solid #00bfb1;
  }
`

const TimeSelect = styled(StyledSelect)`
  .MuiSelect-root {
    padding-right: 17px !important;
    padding-left: 5px;
  }
  svg {
    right: 1px;
  }
`

const ErrorText = styled(FormHelperText)`
  margin: 3px 14px;
  font-size: 15px;
  text-align: center;
  font-weight: 700;
`

const CertificateItem = styled.div`
  background: #f5f5f5;
  border: 1px solid #aaa;
  border-radius: 2px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
  padding: 7px 12px;
`

const AttireContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`

const AttireItem = styled.div`
  text-align: center;
  width: 125px;
  border-radius: 2px;
  padding: 4px;
  border: 1px solid rgb(170, 170, 170);
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 2px inset;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
`

const AttireWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const AttireButton = styled(IconButton)(
  ({ selected }) => `
  border: 1px solid rgba(113, 114, 116, 0.25);
  background-color: ${selected === 'selected' ? 'rgb(100, 200, 100)' : '#fff'};
  &:hover {
    background-color: ${selected === 'selected' ? 'rgb(100, 200, 100)' : ''};
  }
`,
)

const StyledTextField = styled(TextField)`
  input {
    padding: 7px 12px;
    font-size: 15px;
    height: auto;
  }
  .MuiOutlinedInput-notchedOutline {
    color: #c4cdd5;
  }
  .Mui-focused {
    border: none;
    :not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #00848e !important;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
          0 0 8px rgb(102 175 233 / 60%);
      }
    }
  }
`

const NumInput = styled(StyledTextField)`
  .MuiOutlinedInput-root {
    padding: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`

const StyledAdornment = styled(InputAdornment)`
  height: 36px !important;
  max-height: none !important;
  width: 30px;
  flex-direction: column;
  border-radius: 0 4px 4px 0;
  &.MuiInputAdornment-positionEnd {
    margin-left: 0;
  }
`

const StyledAdornmentButton = styled(IconButton)`
  width: 30px;
  height: 18px;
  border-radius: 0 !important;
  &:nth-of-type(odd) {
    align-items: flex-end;
    padding: 0 0 3px 0;
  }
  &:nth-of-type(even) {
    align-items: flex-start;
    padding: 3px 0 0 0;
  }
`

const WageAdornment = styled(StyledAdornment)`
  background-color: #f6f6f6;
`

const WageButton = styled(StyledAdornmentButton)`
  background-color: #f6f6f6 !important;
  border-left: 1px solid rgba(0, 0, 0, 0.23);
`

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 500,
    },
  },
}

export const SelectPositions = ({ value, onChange, disabled, positions }) => (
  <StyledSelect
    id="name"
    variant="outlined"
    disabled={disabled}
    value={value}
    onChange={onChange}
    fullWidth
    MenuProps={MenuProps}
  >
    {positions.length > 0
      && positions.map((pos) => (
        <MenuItem key={pos.id} value={pos.id}>
          {pos.name}
        </MenuItem>
      ))}
  </StyledSelect>
)

export const SelectBreakHrs = ({ value, disabled, onChange }) => (
  <StyledSelect
    id="breakDuration"
    variant="outlined"
    value={value}
    onChange={onChange}
    disabled={disabled}
    fullWidth
  >
    <MenuItem value={0}>0 Min</MenuItem>
    <MenuItem value={15}>15 Min</MenuItem>
    <MenuItem value={30}>30 Min</MenuItem>
    <MenuItem value={45}>45 Min</MenuItem>
    <MenuItem value={60}>1 Hr</MenuItem>
  </StyledSelect>
)

export const SelectBreakQty = ({ value, disabled, onChange }) => (
  <StyledSelect
    id="breakQty"
    variant="outlined"
    value={value}
    onChange={onChange}
    disabled={disabled}
    fullWidth
  >
    <MenuItem value={1}>1</MenuItem>
    <MenuItem value={2}>2</MenuItem>
    <MenuItem value={3}>3</MenuItem>
    <MenuItem value={4}>4</MenuItem>
    <MenuItem value={5}>5</MenuItem>
  </StyledSelect>
)

export const QuantityField = ({
  className,
  value,
  hasPaid,
  onChange,
  min = 1,
}) => {
  const changeWage = (type) => {
    if (type === 'minus') {
      if (value === min) {
        return
      }
      onChange(Number(value) - 1)
    }
    if (type === 'plus') {
      onChange(Number(value) + 1)
    }
  }
  const handleChange = (e) => {
    if (hasPaid && Number(e.target.value) < value) {
      onChange(value)
    }
    if (Number(e.target.value) < 1) {
      onChange(1)
    } else {
      onChange(Number(e.target.value))
    }
  }
  return (
    <NumInput
      id="qty"
      className={className}
      type="number"
      variant="outlined"
      value={value}
      onChange={onChange}
      onBlur={handleChange}
      fullWidth
      placeholder="Quantity"
      InputProps={{
        endAdornment: (
          <StyledAdornment position="end">
            <StyledAdornmentButton
              type="button"
              onClick={() => changeWage('plus')}
            >
              <ArrowDropUp />
            </StyledAdornmentButton>
            <StyledAdornmentButton
              type="button"
              onClick={() => changeWage('minus')}
            >
              <ArrowDropDown />
            </StyledAdornmentButton>
          </StyledAdornment>
        ),
      }}
    />
  )
}

export const WageField = ({
  className,
  value,
  onChange,
  min,
  nullable,
  hasError,
  errorWage,
  setErrorWage,
  disabled,
}) => {
  const changeWage = (type) => {
    if (type === 'minus') {
      if (value === min) {
        return
      }
      onChange(Number(value) - 0.5)
    }
    if (type === 'plus') {
      onChange(Number(value) + 0.5)
    }
    if (hasError) {
      setErrorWage('')
    }
  }
  const handleChangeWage = (e) => {
    if (Number(e.target.value) < min) {
      onChange(min)
    } else {
      onChange(
        nullable && e.target.value === '' ? null : Number(e.target.value),
      )
    }
    if (hasError) {
      if (Number(e.target.value) < min) {
        setErrorWage(
          `The minimum permitted Hourly Wage is ${formattedCurrencyTransaction(
            min,
          )}`,
        )
      } else {
        setErrorWage('')
      }
    }
  }
  return (
    <NumInput
      id="wage"
      className={className}
      type="number"
      variant="outlined"
      value={value === null ? '' : value}
      onChange={onChange}
      onBlur={handleChangeWage}
      fullWidth
      placeholder="Wage"
      error={!!errorWage}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <WageAdornment position="end">
            <WageButton
              size="small"
              type="button"
              disabled={disabled}
              onClick={() => changeWage('plus')}
            >
              <ArrowDropUp />
            </WageButton>
            <WageButton
              size="small"
              type="button"
              disabled={disabled}
              onClick={() => changeWage('minus')}
            >
              <ArrowDropDown />
            </WageButton>
          </WageAdornment>
        ),
      }}
    />
  )
}

export const SelectAgency = ({ value, onChange, agencies }) => (
  <StyledSelect
    id="departmentId"
    variant="outlined"
    value={value}
    onChange={onChange}
    fullWidth
  >
    {agencies.length > 0
      && agencies.map((agc) => {
        const { department } = agc
        return (
          <MenuItem key={department.id} value={department.id}>
            {`${department.agency.name}`}
          </MenuItem>
        )
      })}
  </StyledSelect>
)

export const SelectTime = ({ id, value, onChange, listTime }) => (
  <TimeSelect
    id={id}
    variant="outlined"
    value={value}
    onChange={onChange}
    MenuProps={MenuProps}
    fullWidth
  >
    {listTime.map((i) => (
      <MenuItem key={`start-hour-${i}`} value={zeroPad(i, 2)}>
        {zeroPad(i, 2)}
      </MenuItem>
    ))}
  </TimeSelect>
)

export const SelectCertification = ({
  certificate,
  certifications,
  setCertificate,
}) => {
  const {
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useFormContext()
  const watchCertification = watch('shiftRequirements.certificationIds')
  return (
    <Wrapper xs={12} item container>
      <LabelField>Certifications</LabelField>
      <Grid xs={12} item container spacing={1}>
        <Grid item xs={8}>
          <StyledSelect
            id="certifications"
            variant="outlined"
            value={certificate}
            onChange={({ target }) => setCertificate(target.value)}
            fullWidth
            displayEmpty
            isdefault={certificate === '' ? 'default' : 'none'}
            MenuProps={MenuProps}
          >
            <MenuItem value="" disabled>
              Select a type of Certificate
            </MenuItem>
            {certifications.length > 0
              && certifications.map((cert) => (
                <MenuItem key={cert.id} value={cert.id}>
                  {cert.name}
                </MenuItem>
              ))}
          </StyledSelect>
        </Grid>
        <Grid item xs={4} textAlign="right">
          <StyledButton
            fullWidth
            type="button"
            onClick={() => {
              const listCert = getValues('shiftRequirements.certificationIds')
              if (listCert.includes(certificate)) {
                const cert = certifications.find(
                  (certs) => certs.id === certificate,
                )
                setError('certificationIds', {
                  type: 'duplicate',
                  message: `${cert.name} is already selected`,
                })
              } else if (certificate) {
                clearErrors('certificationIds')
                listCert.push(certificate)
              }
              setValue('shiftRequirements.certificationIds', listCert)
            }}
          >
            Add
          </StyledButton>
        </Grid>
        {errors.certificationIds && (
          <Grid item xs={12}>
            <ErrorText error>{errors.certificationIds.message}</ErrorText>
          </Grid>
        )}
        {watchCertification.length > 0
          && watchCertification.map((cert, index) => {
            const selectedCert = certifications.find(
              (certs) => certs.id === cert,
            )
            return (
              <Grid key={selectedCert.id} item xs={6} container>
                <CertificateItem>
                  <div>{selectedCert.name}</div>
                  <IconButton
                    size="small"
                    type="button"
                    onClick={() => {
                      const listCert = getValues(
                        'shiftRequirements.certificationIds',
                      )
                      listCert.splice(index, 1)
                      setValue('shiftRequirements.certificationIds', listCert)
                    }}
                  >
                    <DeleteIcon fontSize="small" color="error" />
                  </IconButton>
                </CertificateItem>
              </Grid>
            )
          })}
      </Grid>
    </Wrapper>
  )
}

export const SelectAttire = ({
  attires,
  attireOrder,
  watchAttire,
  handleAttireSelected,
  disabled,
}) => (
  <AttireContainer>
    {attireOrder.length > 0
      && attires
      && attireOrder.map((order) => (
        <AttireItem key={order}>
          <div>{order}</div>
          <AttireWrap>
            {attires[order].map((list) => (
              <AttireButton
                key={list.id}
                type="button"
                id={`attire ${list.name}`}
                selected={watchAttire.includes(list.id) ? 'selected' : ''}
                onClick={() => {
                  if (disabled) {
                    return
                  }
                  const disables = []
                  const atLeast = watchAttire.filter((att) => ['1', '2', '3', '4'].includes(att))
                  if (!disables.includes(list.id)) {
                    if (atLeast.length === 1 && atLeast[0] === list.id) {
                      return
                    }
                    handleAttireSelected(list.id)
                  }
                }}
              >
                <img src={list.image} alt="attire" width="30" height="30" />
              </AttireButton>
            ))}
          </AttireWrap>
        </AttireItem>
      ))}
  </AttireContainer>
)
