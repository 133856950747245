import React, { useState } from 'react'
import { ShiftDialog } from './ShiftDialog'

export default {
  title: 'ShiftModal/Dialog',
  component: ShiftDialog,
  argTypes: {
    open: {
      control: { type: 'boolean' },
      defaultValue: true,
    },
    handleClose: { action: 'Close Backdrop' },
    handleCreatePosition: { action: 'Create Position' },
  },
}

const Template = (args) => {
  const {
    open,
    handleClose,
    handleCreatePosition,
  } = args

  const [positions] = useState([
    { name: 'General', id: 0 },
    { name: 'Bartender', id: 1 },
    { name: 'Chef', id: 2 },
  ])

  const partnerships = []

  return (
    <ShiftDialog
      open={open}
      handleClose={handleClose}
      handleCreatePosition={handleCreatePosition}
      positions={positions}
      partnerships={partnerships}
    />
  )
}

export const Main = Template.bind({})
