import React, { useState, useEffect, useRef } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import _ from 'lodash'
import styled from '@emotion/styled'
import { useDebouncedCallback } from 'use-debounce'
import { ThreadMessage } from './Thread/ThreadMessage'
import { Header } from './Header'
import { NewButton } from './UI/NewButton'
import { FooterWrapper } from './UI/Footer'
import { MESSAGE_TABS, inRange } from '../../lib/constants'
import { SearchBar } from './Users/SearchBar'
import { Loading } from '../Icons/Loading'

import GetRooms from './graphql/queries/getRooms.gql'
import GetSearchChat from './graphql/queries/searchChat.gql'

const MessageWrapper = styled.div(
  ({ hide }) => `
  display: ${hide ? 'none' : 'block'}
`,
)

const SearchWrapper = styled.div`
  position: sticky;
  top: -10px;
  background-color: #fff;
  z-index: 1;
`

const LIMIT = 20

const findTopLevelParent = (departments, currentDepartmentId) => {
  const currentDept = departments.find(
    (dept) => dept.id === currentDepartmentId,
  )
  if (currentDept && currentDept.parentId) {
    const parentDept = departments.find(
      (dept) => dept.id === currentDept.parentId,
    )
    if (parentDept.parentId) {
      return findTopLevelParent(departments, parentDept.id)
    }
    return parentDept
  }
  return currentDept
}

export const Messages = ({
  groupMessage,
  isOrganizer,
  switchProfile,
  userId,
  updateObj,
  panel,
  setPanel,
  setThread,
  organizationId,
  listFacilities,
  departmentId,
  setSelectedFacility,
  selectedFacility,
}) => {
  const roomsRef = useRef(null)
  const [facility, setFacility] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [page, setPage] = useState(1)
  const [totalRooms, setTotalRooms] = useState(0)
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [messages, setMessages] = useState([])
  const [searchIds, setSearchIds] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [loadSearch, setLoadSearch] = useState(false)

  const { loading } = useQuery(GetRooms, {
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: LIMIT,
      page,
      organizationId,
      departmentId: switchProfile ? selectedFacility : departmentId,
    },
    onCompleted: ({ currentUser }) => {
      setTotalRooms(currentUser.availableRooms.pagination.totalCount)
      setData((prev) => _.uniqBy([...prev, ...currentUser.availableRooms.items], 'id'))
      setMessages((prev) => _.uniqBy([...prev, ...currentUser.availableRooms.items], 'id'))
      setFacility(isOrganizer && findTopLevelParent(currentUser.organizer.partOfDepartments, departmentId))
    },
    skip: !organizationId,
  })

  const [getSearchChat] = useLazyQuery(GetSearchChat, {
    context: {
      headers: isOrganizer && {
        'department-id': _.get(facility, 'id') || departmentId,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ searchChat }) => {
      if (searchChat.items.length === 0) {
        setLoadSearch(false)
        setMessages([])
      }
      setSearchIds(searchChat.items.map((item) => item.id))
    },
  })

  useEffect(() => {
    if (searchIds.length > 0 && data.length > 0 && !loading) {
      setLoadSearch(false)
      if (searchIds.every((id) => data.some((dt) => dt.id === id))) {
        setMessages(data.filter((dt) => searchIds.some((id) => dt.id === id)))
      } else if (data.length < totalRooms) {
        setPage((prev) => prev + 1)
      }
    }
  }, [data, loading, searchIds, totalRooms])

  useEffect(() => {
    if (updateObj) {
      setData((prev) => {
        const listRoom = [...prev]
        const index = listRoom.findIndex((room) => room.id === updateObj.id)
        if (index >= 0) {
          listRoom[index] = updateObj
        } else {
          listRoom.unshift(updateObj)
        }
        return listRoom
      })
    }
  }, [updateObj])

  useEffect(() => {
    const { current } = roomsRef
    const loadMore = (e) => {
      const el = e.target
      if (data && !isSearching) {
        if (
          inRange(
            el.scrollTop + el.clientHeight,
            el.scrollHeight - 10,
            el.scrollHeight + 10,
          )
        ) {
          if (data.length < totalRooms && !loading) {
            setPage((prev) => prev + 1)
            setCurrentPage((prev) => prev + 1)
          }
          if (data.length > messages.length) {
            const currentPg = currentPage + 1
            setMessages(data.slice(0, currentPg * LIMIT))
            setCurrentPage(currentPg)
          }
        }
      }
    }
    current.addEventListener('scroll', loadMore)
    return () => {
      current.removeEventListener('scroll', loadMore)
    }
  }, [messages, loading, totalRooms, data, currentPage, isSearching])

  const handleNewMessage = () => setPanel(MESSAGE_TABS.USERS)

  const debounce = useDebouncedCallback((val) => {
    setSearch(val)
    if (val.length >= 3) {
      setIsSearching(true)
      setLoadSearch(true)
      getSearchChat({
        variables: {
          query: val,
          page,
        },
      })
    }
    if (val < 3) {
      setSearchIds([])
      setIsSearching(false)
      setMessages(data.slice(0, currentPage * LIMIT))
    }
  }, 500)

  return (
    <>
      <div className="modal-header">
        <Header
          groupMessage={groupMessage}
          switchProfile={switchProfile}
          isOrganizer={isOrganizer}
          panel={panel}
          setPanel={setPanel}
          listFacilities={listFacilities}
          selectedFacility={selectedFacility}
          setSelectedFacility={setSelectedFacility}
        />
      </div>
      <div className="modal-body" ref={roomsRef}>
        <SearchWrapper>
          <SearchBar
            search={search}
            onChange={(val) => debounce(val)}
            placeholder="Search messages"
          />
        </SearchWrapper>
        {loadSearch ? (
          <div>
            <Loading />
          </div>
        ) : (
          <MessageWrapper>
            {messages.length > 0
              && [...messages]
                .sort(
                  (f, l) => l.lastMessageAt
                    && dayjs(l.lastMessageAt).diff(dayjs(f.lastMessageAt)),
                )
                .map((room) => (
                  <ThreadMessage
                    userId={userId}
                    setPanel={setPanel}
                    setThread={setThread}
                    key={room.id}
                    room={room}
                  />
                ))}
            {loading && (
              <div>
                <Loading />
              </div>
            )}
          </MessageWrapper>
        )}
      </div>
      <div className="modal-footer">
        <FooterWrapper>
          <NewButton text="New Message" handler={handleNewMessage} />
        </FooterWrapper>
      </div>
    </>
  )
}
