import React from 'react'

import { BusinessTooltip } from './BusinessTooltip'

export default {
  title: 'Onboarding/Scheduling/BusinessTooltip',
  component: BusinessTooltip,
}

const Template = (args) => <BusinessTooltip {...args} />

export const Main = Template.bind({})
Main.args = {

}
