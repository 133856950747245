import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Drawer, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import * as yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { PositionInfo } from './PositionInfo'

import ModifyShift from './mutations/modifyShift.gql'
import AddTag from './mutations/addTag.gql'
import RemoveTag from './mutations/removeTag.gql'

dayjs.extend(utc)
dayjs.extend(timezone)

const StyledDrawer = styled(Drawer)(
  ({ theme }) => `
  .MuiPaper-root {
    width: 450px;
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
`,
)

const HeaderDrawer = styled.div`
  font-weight: 700;
  font-size: 17px;
  padding: 10px;
  background: #f5f5f5;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div``

const BodyDrawer = styled.div`
  height: calc(100vh - 145px);
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-thumb {
    height: 6px;
    width: 12px;
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 15px;
    background-color: #dddddd;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`

const FooterDrawer = styled.div`
  background: #f5f5f5;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1em;
  border-top: 1px solid #dddddd;
`

const StyledButton = styled(Button)`
  color: #ffffff;
  background-color: #00a7a4;
  border: 1px solid #007f79;
  font-weight: 600;
  width: 320px;
  :hover {
    background-color: #00bfb1;
    border: 1px solid #00bfb1;
  }
  margin-bottom: 24px;
`

const schema = yup.object().shape({
  shiftDetails: yup.object().shape({
    id: yup.string().nullable(),
    eventId: yup.string().required(),
    date: yup.string().nullable(),
    fullTimezoneName: yup.string().nullable(),
    adminPositionListId: yup.string().required(),
    qty: yup.number().required(),
    startHrs: yup.string().required(),
    startMins: yup.string().required(),
    endHrs: yup.string().required(),
    endMins: yup.string().required(),
    wage: yup.number().transform((value) => (_.isNaN(Number(value)) ? undefined : value)),
    details: yup.string().nullable(),
    breakDuration: yup.number(),
    breakQty: yup.number(),
    partnershipId: yup.string().nullable(),
    unpaid: yup.boolean(),
    positionType: yup.string().required(),
  }),
  shiftRequirements: yup.object().shape({
    ratingRequired: yup.number().required(),
    certificationIds: yup.array().of(yup.string())
      .nullable(),
    heavyLifting: yup.boolean(),
    assignOnly: yup.boolean(),
  }),
  attireDetails: yup.object().shape({
    attireIds: yup.array().of(yup.string()),
    attireDescription: yup.string().nullable(),
  }),
  orderDetails: yup.object().shape({
    contactName: yup.string().nullable(),
    contactNumber: yup.string().nullable(),
    orderNotes: yup.string().nullable(),
    poNumber: yup.string().nullable(),
    signUpLocation: yup.string().nullable(),
  }),
})

const defaultValues = {
  shiftDetails: {
    id: '',
    eventId: '',
    date: '',
    fullTimezoneName: '',
    adminPositionListId: '',
    qty: '',
    startHrs: '10',
    startMins: '00',
    endHrs: '19',
    endMins: '00',
    breakDuration: 0,
    breakQty: 0,
    unpaid: false,
    partnershipId: '',
    wage: '',
    details: '',
    positionType: '',
  },
  shiftRequirements: {
    ratingRequired: 3,
    certificationIds: [],
    heavyLifting: false,
    assignOnly: false,
  },
  attireDetails: {
    attireIds: [],
    attireDescription: '',
  },
  orderDetails: {
    contactName: '',
    contactNumber: '',
    orderNotes: '',
    poNumber: '',
    signUpLocation: '',
  },
}

export const PositionModal = ({
  open,
  onClose,
  dates,
  type,
  isEdit,
  eventId,
  positionId,
  title,
  showTagManagement,
}) => {
  const posType = type.replace('-', '').toUpperCase()
  const [tags, setTags] = useState([])
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    reValidateMode: 'all',
    defaultValues: {
      ...defaultValues,
      shiftDetails: {
        ...defaultValues.shiftDetails,
        eventId,
        id: positionId,
        positionType: posType,
      },
    },
  })
  const watchShiftQty = watch('shiftDetails.qty')

  const [addTag] = useMutation(AddTag)
  const [removeTag] = useMutation(RemoveTag)

  const [modifyShift, { loading }] = useMutation(ModifyShift, {
    onCompleted: async ({ managePositionOfEvent }) => {
      const promises = []
      const listNew = _.differenceBy(tags, managePositionOfEvent.position.tags, (tag) => tag.id)
      const listDelete = _.differenceBy(managePositionOfEvent.position.tags, tags, (tag) => tag.id)
      if (listNew.length > 0) {
        listNew.map((tag) => promises.push(
          addTag({
            variables: {
              positionId: managePositionOfEvent.position.id,
              tagId: tag.id,
            },
          }),
        ))
      }
      if (listDelete.length > 0) {
        listDelete.map((tag) => promises.push(
          removeTag({
            variables: {
              positionId: managePositionOfEvent.position.id,
              tagId: tag.id,
            },
          }),
        ))
      }
      const result = await Promise.all(promises)
      if (promises.length === 0 || result.length > 0) {
        window.location.reload()
      }
    },
  })

  const onSubmit = (data) => {
    const { shiftDetails, shiftRequirements, attireDetails, orderDetails } = data
    const date = dayjs.tz(shiftDetails.date, shiftDetails.fullTimezoneName)
    const startAt = date.set('h', Number(shiftDetails.startHrs)).set('m', Number(shiftDetails.startMins))
      .set('ms', 0)
    let endAt = date.set('h', Number(shiftDetails.endHrs)).set('m', Number(shiftDetails.endMins))
      .set('ms', 0)
    if (startAt.diff(endAt) > 0) {
      endAt = endAt.add(1, 'd')
    }
    modifyShift({
      variables: {
        position: {
          shiftDetails: {
            ..._.omit(shiftDetails, ['date', 'fullTimezoneName', 'startHrs', 'startMins', 'endHrs', 'endMins']),
            startTime: startAt.format(),
            endTime: endAt.format(),
          },
          shiftRequirements,
          attireDetails,
          orderDetails,
        },
      },
    })
  }
  return (
    <StyledDrawer open={open} anchor="right" onClose={onClose} hideBackdrop={!isEdit}>
      <FormProvider
        control={control}
        watch={watch}
        setValue={setValue}
        getValues={getValues}
        setError={setError}
        clearErrors={clearErrors}
        reset={reset}
        formState={{ errors }}
      >
        <form>
          <HeaderDrawer>
            <Title>{title}</Title>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </HeaderDrawer>
          <BodyDrawer>
            <PositionInfo
              open={open}
              type={posType}
              stringType={type}
              isEdit={isEdit}
              eventId={eventId}
              positionId={positionId}
              defaultValues={defaultValues}
              dates={dates}
              tags={tags}
              setTags={setTags}
              showTagManagement={showTagManagement}
            />
          </BodyDrawer>
          <FooterDrawer>
            <StyledButton disabled={loading} type="submit" onClick={handleSubmit(onSubmit)}>
              {isEdit ? `Edit (${watchShiftQty}) ${type} Shifts` : `Create (${watchShiftQty}) ${type} Shifts`}
            </StyledButton>
          </FooterDrawer>
        </form>
      </FormProvider>
    </StyledDrawer>
  )
}
