import React, { useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import styled from '@emotion/styled'
import parse from 'autosuggest-highlight/parse'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { Autocomplete, Box, FormHelperText, Grid, TextField, Typography } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import _ from 'lodash'
import { useMutation } from '@apollo/client'
import { Label } from './Label'
import { GOOGLE_API_KEY } from '../../lib/constants'
import GetGoogleApiAddress from './mutation/getGoogleAddress.gql'

const Styled = styled.div`
  display: grid;
`

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-input {
    padding: 0 !important;
  }
`

export const LocationDialog = ({
  location,
  locationHandler,
  errorLocation,
  setErrorLocation,
  addressLocation,
  cityHandler,
  regionHandler,
  countryHandler,
}) => {
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: GOOGLE_API_KEY,
  })

  const [getGoogleAddress, { data }] = useMutation(GetGoogleApiAddress)

  useEffect(() => {
    if (location && !addressLocation) {
      getGoogleAddress({ variables: { address: location } })
    }
  }, [location, addressLocation, getGoogleAddress])

  useEffect(() => {
    if (addressLocation) {
      const { city, region, country } = addressLocation
      cityHandler(city)
      regionHandler(region)
      countryHandler(country)
    }
  }, [addressLocation, cityHandler, countryHandler, regionHandler])

  useEffect(() => {
    if (data) {
      const components = {}
      JSON.parse(data.googleapiAddress.data).results[0].address_components.map((key) => key.types.map((key1) => {
        components[key1] = key.long_name
        return components
      }))
      if (!_.every(['locality', 'administrative_area_level_1', 'country'], _.partial(_.has, components))) {
        setErrorLocation('This location is invalid!')
      }
      cityHandler(components.locality)
      regionHandler(components.administrative_area_level_1)
      countryHandler(components.country)
    }
  }, [cityHandler, countryHandler, data, regionHandler, setErrorLocation])

  return (
    <Styled>
      <Label>Location</Label>
      <FormControl error={Boolean(errorLocation)}>
        <StyledAutocomplete
          loading={isPlacePredictionsLoading}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          isOptionEqualToValue={(option, value) => option.description === value.description}
          filterOptions={(x) => x}
          noOptionsText={null}
          options={placePredictions}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={location}
          popupIcon={null}
          onChange={(event, newValue) => {
            locationHandler(newValue)
          }}
          onInputChange={(event, newInputValue) => {
            getPlacePredictions({ input: newInputValue })
          }}
          renderInput={(params) => (
            <TextField
              placeholder="Shift Address"
              fullWidth
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          )}
          renderOption={(props, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length]),
            )

            return (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part) => (
                      <span
                        key={`${props.key}-${part.text}`}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )
          }}
        />
        {errorLocation && <FormHelperText>{errorLocation}</FormHelperText>}
      </FormControl>
    </Styled>
  )
}
