import React from 'react'
import styled from '@emotion/styled'
import { useDebouncedCallback } from 'use-debounce'

const SearchContainer = styled.div`
  padding-left: 15px;
  marginbottom: 15px;
`

const SearchField = styled.input`
  border-radius: 4px;
`

export const SearchBar = ({ search, setSearch, onChange, placeholder }) => {
  const debounce = useDebouncedCallback((val) => {
    setSearch(val)
  }, 500)

  return (
    <SearchContainer className="row">
      <div className="col-md-12 margin-vert-10">
        <SearchField
          placeholder={placeholder}
          defaultValue={search}
          onChange={({ target }) => (onChange ? onChange(target.value) : debounce(target.value))}
          className="form-control"
          type="text"
        />
      </div>
    </SearchContainer>
  )
}
