import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'
import { FooterWrapper } from './UI/Footer'
import { NewButton } from './UI/NewButton'
import { CHAT_ROOM_TYPE, MESSAGE_TABS } from '../../lib/constants'
import { Header } from './UI/Header'

import CreateGroup from './graphql/mutations/createGroup.gql'
import AddParticipants from './graphql/mutations/addParticipants.gql'

const Title = styled.div`
  width: 225px;
  margin: 50px auto;
  text-align: center;
  font-size: 16px;
`

const GroupName = styled.div`
  margin: 0 auto;
  width: 252px;
  input {
    padding: 7px 13px;
    font-size: 14px;
  }
`

export const NewGroup = ({ setPanel, thread, setThread, setGroupName }) => {
  const [name, setName] = useState('')

  const [addParticipants] = useMutation(AddParticipants)

  const [createGroup, { loading }] = useMutation(CreateGroup, {
    onCompleted: ({ createGroupChat }) => {
      if (thread.users.length > 0) {
        addParticipants({
          variables: {
            roomId: createGroupChat.chatRoom.id,
            participantIds: thread.users.map((sel) => sel.id),
            canViewHistory: true,
          },
        }).then(() => {
          setThread((prev) => ({ ...prev, roomId: createGroupChat.chatRoom.id }))
          setPanel(MESSAGE_TABS.THREAD)
        })
      } else {
        setThread({
          type: CHAT_ROOM_TYPE.GROUP,
          roomId: createGroupChat.chatRoom.id,
          users: [],
        })
        setPanel(MESSAGE_TABS.GROUP_USER)
        setGroupName(createGroupChat.chatRoom.name)
      }
    },
  })

  const handleBack = () => {
    setPanel(MESSAGE_TABS.GROUPS)
  }

  const handleAddGroup = () => {
    createGroup({
      variables: {
        name,
      },
    })
  }

  return (
    <>
      <div className="modal-header">
        <Header tab={MESSAGE_TABS.NEW_GROUP} setPanel={handleBack} />
      </div>
      <div className="modal-body">
        <Title>Help identify what each group is for by naming your group</Title>
        <GroupName>
          <TextField
            variant="outlined"
            fullWidth
            value={name}
            onChange={({ target }) => setName(target.value)}
            placeholder="Group Name"
          />
        </GroupName>
      </div>
      <div className="modal-footer">
        <FooterWrapper>
          <NewButton text="Add Group Name" disabled={!name || loading} handler={handleAddGroup} />
        </FooterWrapper>
      </div>
    </>
  )
}
