import React from 'react'
import { TypeDialog } from './TypeDialog'

export default {
  title: 'ShiftModal/Dialog/Type',
  component: TypeDialog,
  argTypes: {
    shiftTypeHandler: { action: 'Shift Type Handler' },
  },
}

const Template = ({ shiftTypeHandler }) => (
  <TypeDialog
    shiftTypeHandler={shiftTypeHandler}
  />
)
export const Main = Template.bind({})
