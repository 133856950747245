import React, { useMemo } from 'react'
import { Chip, Tooltip, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import RecurringOn from '../Icons/RecurringOn'
import { toDayAndMonth } from '../helpers/time'

const Title = styled.div`
  display: grid;
  grid-template-columns: 40% auto auto;
  column-gap: 10px;

  .date {
    display: flex;
  }
`

const Label = styled.div`
  padding: 0px;
  text-align: left;
  display: grid;
  grid-template-columns: 25px auto auto;
  align-items: center;

  .font {
    padding: 4px 0px;
    font-weight: 700;
    font-size: 16px;
  }

  .label {
    padding: 4px 0px;
    font-size: 9px;
    line-height: 11px;
  }
`

const Notes = styled(FontAwesomeIcon)`
  align-self: center;
  margin-left: 10px;
`

const Icon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  cursor: pointer;
`

const EditIcon = styled(Icon)`
  color: gray;
`

const Date = styled(Chip)`
  margin-right: 5px;
`

const Actions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 7px 0px;
`

const ShiftType = {
  agency: 'A',
  inHouse: 'E',
  hyre: 'H',
}

const LabelIcon = styled.div`
  border-radius: 100%;
  background: ${(props) => {
    if (props.type === ShiftType.agency) {
      return '#064e8a'
    }
    if (props.type === ShiftType.inHouse) {
      return '#909eab'
    }
    if (props.type === ShiftType.hyre) {
      return '#00A7A4'
    }
    return ''
  }};
  padding: 3px 6px;
  color: white;
  text-align: center;
  width: 17px;
`

const ChipLabel = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
`

const DayTooltip = withStyles({
  tooltip: {
    backgroundColor: '#4b4c60',
  },
})(Tooltip)

const DayString = styled.span`
  text-transform: uppercase;
`

const RecurringIcon = styled(RecurringOn)`
  width: 16px;
  height: 16px;
`

const RecurringTooltip = ({ days, endDate }) => {
  const dates = _.join(days, ', ')
  const untilDate = toDayAndMonth(endDate)
  return (
    <div>
      Every
      <DayString>{` ${dates} `}</DayString>
      until
      {` ${untilDate}`}
    </div>
  )
}

export const ShiftTitle = ({ shift, removeHandler }) => {
  const { position, qty, dates, notes, recurring, days, endDate } = shift

  const type = useMemo(() => {
    if (shift.type) {
      return ShiftType[shift.type]
    }
    return ''
  }, [shift])

  const remove = () => removeHandler(shift)

  return (
    <Title>
      <Label>
        <div className="label">
          <LabelIcon type={type}>{type}</LabelIcon>
        </div>
        <Typography className="font">{`${qty}x ${position.name}`}</Typography>
        {notes && <Notes size="1x" icon={faFileAlt} />}
      </Label>
      <div className="date">
        {dates
          && dates.map((date) => (
            <DayTooltip
              title={recurring ? <RecurringTooltip days={days} endDate={endDate} /> : ''}
              placement="bottom"
            >
              <Date
                key={date}
                color="primary"
                label={(
                  <ChipLabel>
                    <div>{dayjs(date).format('MMM DD')}</div>
                    {recurring && <RecurringIcon fill="#FFF" />}
                  </ChipLabel>
                )}
              />
            </DayTooltip>
          ))}
      </div>
      <Actions>
        <Icon onClick={remove} icon={faTrash} />
        <EditIcon disabled icon={faPen} />
      </Actions>
    </Title>
  )
}
