import styled from '@emotion/styled'
import React from 'react'

const StickyBottom = styled.div`
  position: absolute;
  bottom: 70px;
  padding: 8px 25px;
  z-index: 1;
  width: 100%;
  border-top: 1px solid #c4cdd5;
  border-bottom: 1px solid #c4cdd5;
  background: #F9FAFB;
`

export const UserSelected = ({ selected }) => {
  if (selected.length === 0) {
    return null
  }
  return <StickyBottom>{`Users Selected (${selected.length})`}</StickyBottom>
}
