import React from 'react'
import {
  Paper,
  DialogTitle,
  Typography,
  FormControl,
  InputAdornment,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled'
import { Label } from './Label'
import { Field } from './Field'

const Container = styled(Paper)`
  width: 100%;
  padding-bottom: 20px;
`

const Title = styled(DialogTitle)`
  padding: 20px !important;
  border-bottom: 1px solid #dfe3e8;

  .titleTypography {
    font-weight: 700;
    font-size: 16px;
    color: #3e3e4c;
  }
`

const Wrapper = styled.div`
  padding: 20px;
`

const CheckForAll = styled(FormControlLabel)`
  margin: 0 auto;
  text-transform: none;
  .MuiCheckbox-root {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const OrderDetails = ({
  contactName,
  contactPhone,
  poNumber,
  signupLocation,
  orderNotes,
  contactNameHandler,
  contactPhoneHandler,
  poNumberHandler,
  signupLocationHandler,
  orderNotesHandler,
  shift,
  updateHandler,
  orderForAll,
  setOrderForAll,
  shiftIndex,
}) => {
  const handleChange = (target, key, setter) => {
    if (orderForAll) {
      setter(target.value)
    } else {
      updateHandler({ ...shift, ...{ [key]: target.value } }, shiftIndex)
    }
  }

  return (
    <Container>
      <Title disableTypography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography className="titleTypography">Order Details</Typography>
          </Grid>
          <Grid item>
            <CheckForAll
              control={(
                <Checkbox
                  checked={orderForAll}
                  onChange={({ target }) => setOrderForAll(target.checked, shift)}
                  color="primary"
                  size="small"
                />
              )}
              label="Apply details to all sets of shifts"
            />
          </Grid>
        </Grid>
      </Title>
      <Wrapper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sx={6}>
                <Label>Staff Sign-up Location</Label>
                <FormControl>
                  <Field
                    placeholder="Add meeting spot"
                    onChange={({ target }) => handleChange(target, 'signupLocation', signupLocationHandler)}
                    value={signupLocation}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Label className="contactLabel">Contact Details</Label>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <FormControl className="contactName">
                      <Field
                        placeholder="Name"
                        onChange={({ target }) => handleChange(target, 'contactName', contactNameHandler)}
                        value={contactName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className="contactPhone">
                      <Field
                        placeholder="Phone No."
                        onChange={({ target }) => handleChange(target, 'contactPhone', contactPhoneHandler)}
                        value={contactPhone}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Label className="poLabel">Add PO Number</Label>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className="po">
                      <Field
                        onChange={({ target }) => handleChange(target, 'poNumber', poNumberHandler)}
                        value={poNumber}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sx={6}>
                <Label>Order Notes</Label>
                <FormControl className="orderNotes">
                  <Field
                    value={orderNotes}
                    onChange={({ target }) => handleChange(target, 'orderNotes', orderNotesHandler)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faFileAlt} className="icon" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  )
}
