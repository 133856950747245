import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MESSAGE_TABS } from '../../../lib/constants'

const useStyles = makeStyles({
  button: {
    padding: 0,
    border: 0,
    marginTop: '-5px',
    backgroundColor: 'transparent',

    '& span': {
      fontSize: '26px',
      color: '#828282',
    },
  },
})

export const BackButton = ({ setPanel }) => {
  const classes = useStyles()

  const handler = () => {
    setPanel(MESSAGE_TABS.MESSAGES)
  }

  return (
    <button
      className={classes.button}
      aria-label="back"
      type="button"
      id="backBtn"
      onClick={handler}
    >
      <span aria-hidden="true">&lt;</span>
    </button>
  )
}
