import React from 'react'
import styled from '@emotion/styled'
import { Shift } from './Shift'
import { OrderDetails } from './OrderDetails'

const Container = styled.div`
  div:last-of-type {
    margin-bottom: 0px;
  }
`

const WrapperOrder = styled.div`
  margin-bottom: 10px;
`

export const List = ({
  shifts,
  hasStaff,
  removeHandler,
  updateHandler,
  partnerships,
  staffDepartments,
  isFacility,
  orderForAll,
  setOrderForAll,
  showTagManagement,
  dynamicStaffWage,
}) => (
  <Container>
    {shifts
      && shifts.map((shift, index) => (
        <>
          <Shift
            shift={shift}
            hasStaff={hasStaff}
            key={`shift${shift.index}_${shift.position.id}_${shift.qty}_${shift.startAt}_${shift.endAt}`}
            shiftIndex={index}
            removeHandler={removeHandler}
            updateHandler={updateHandler}
            partnerships={partnerships}
            staffDepartments={staffDepartments}
            isFacility={isFacility}
            showTagManagement={showTagManagement}
            dynamicStaffWage={dynamicStaffWage}
          />
          {!orderForAll && shift.type === 'agency' && (
            <WrapperOrder>
              <OrderDetails
                shift={shift}
                contactName={shift.contactName}
                contactPhone={shift.contactPhone}
                poNumber={shift.poNumber}
                signupLocation={shift.signupLocation}
                orderNotes={shift.orderNotes}
                updateHandler={updateHandler}
                orderForAll={orderForAll}
                setOrderForAll={setOrderForAll}
                shiftIndex={index}
              />
            </WrapperOrder>
          )}
        </>
      ))}
  </Container>
)
