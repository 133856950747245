import React from 'react'
import styled from '@emotion/styled'
import dayjs from '@date-io/dayjs'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Typography } from '@material-ui/core'
import { Rules } from './Rules'

const DatePicker = styled(KeyboardDatePicker)`
  input {
    padding: 8px;
  }
`

const StyledWrapper = styled.div`
  align-self: flex-end;
  .radioContainer {
    display: inline;
    margin-left: 15px;
  }
`

export const RecurringSelection = ({ days, daysHandler, recurring, endDate, endDateHandler }) => (
  <StyledWrapper>
    {recurring && (
      <>
        <Rules days={days} daysHandler={daysHandler} />
        <Typography display="inline">Recurring Until</Typography>
        <div>
          <MuiPickersUtilsProvider utils={dayjs}>
            <DatePicker
              clearable
              value={endDate}
              minDate={new Date()}
              onChange={endDateHandler}
              format="MMM DD, YYYY"
            />
          </MuiPickersUtilsProvider>
        </div>
      </>
    )}
  </StyledWrapper>
)
