import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Checkbox, Grid } from '@material-ui/core'
import { Avatar } from '../components/Avatar'
import { CHAT_ROOM_TYPE, MESSAGE_TABS } from '../../../lib/constants'

const useStyles = makeStyles({
  userContainer: {
    paddingLeft: '15px',
    marginBottom: '15px',
  },

  notSelect: {
    pointerEvents: 'none',
  },

  userLabel: {
    fontSize: '16px',
  },
})

export const User = ({
  user,
  departmentId,
  switchProfile,
  facId,
  isOrganizer,
  setThread,
  setPanel,
  selected,
  setSelected,
}) => {
  const classes = useStyles()
  const hasChecked = useMemo(() => !!selected.find((u) => u.id === user.id), [selected, user])

  const handler = () => {
    setThread({
      roomId: null,
      users: [user],
      type: CHAT_ROOM_TYPE.DIRECT,
      departmentId: switchProfile ? facId : departmentId,
    })
    setPanel(MESSAGE_TABS.THREAD)
  }

  const handleSelect = () => {
    const list = [...selected]
    const index = list.findIndex((u) => u.id === user.id)
    if (index >= 0) {
      list.splice(index, 1)
    } else {
      list.push(user)
    }
    setSelected(list)
  }

  return (
    <Grid container>
      <Grid item xs={isOrganizer ? 10 : 12}>
        <div
          className={`${classes.userContainer} row ${selected.length > 0 && classes.notSelect}`}
          role="link"
          tabIndex={0}
          onClick={handler}
          onKeyDown={() => {}}
        >
          <div className="col-xs-2 col-sm-2 col-md-2">
            <Avatar thumbnail={user.avatar.thumb} />
          </div>
          <div className={`${classes.userLabel} col-xs-10 col-sm-10 col-md-10 padding-vert-10`}>{user.fullName}</div>
        </div>
      </Grid>
      {isOrganizer && (
        <Grid item xs={2}>
          <Checkbox checked={hasChecked} onChange={handleSelect} color="primary" />
        </Grid>
      )}
    </Grid>
  )
}
