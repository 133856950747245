import React from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import dayjsAdapter from '@date-io/dayjs'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { Label } from './Label'

const Styled = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 5px;
  width: 100%;
`

const DatePicker = styled(KeyboardDatePicker)`
  input {
    padding: 8px;
    &.Mui-disabled {
      -webkit-text-fill-color: #cccccc;
    }
  }
`

export const DateDialog = ({ dates, datesHandler, disabled }) => {
  const datesLocalHandler = (date) => {
    const dateObject = dayjs(date).startOf('D')
    datesHandler([dateObject.toISOString()])
  }

  return (
    <MuiPickersUtilsProvider utils={dayjsAdapter}>
      <Styled>
        <Label>Shift Dates(s)</Label>
        <DatePicker
          disabled={disabled}
          value={dates[0]}
          inputVariant="outlined"
          onChange={datesLocalHandler}
          autoOk
        />
      </Styled>
    </MuiPickersUtilsProvider>
  )
}
