import React, { useEffect, useState } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { useCookies } from 'react-cookie'
import { Messages } from './Messages'
import { Users } from './Users'
import { Groups } from './Groups'
import { Thread } from './Thread'
import { Settings } from './Settings'
import { MESSAGE_TABS, SOCKET_ACTION_TYPE } from '../../lib/constants'
import { NewGroup } from './NewGroup'
import { GroupUser } from './GroupUser'
import { GroupSetting } from './GroupSetting'

import GetUser from './graphql/queries/getUser.gql'
import OnChatNotify from './graphql/subscriptions/OnChatNotify.gql'

export const Message = ({ setNotifyMsg, groupMessage, showTagManagement }) => {
  const [cookies] = useCookies(['current_organization_id'])
  const [userId, setUserId] = useState('')
  const [isOrganizer, setIsOrganizer] = useState(null)
  const [settings, setSettings] = useState(null)
  const [panel, setPanel] = useState(MESSAGE_TABS.MESSAGES)
  const [thread, setThread] = useState({
    type: null,
    roomId: null,
    users: [],
    departmentId: '',
  })
  const [selected, setSelected] = useState([])
  const [updateObj, setUpdateObj] = useState(null)
  const [listFacilities, setListFacilities] = useState([])
  const [selectUserFac, setSelectUserFac] = useState(null)
  const [selectedFacility, setSelectedFacility] = useState(null)
  const [organizationId, setOrganizationId] = useState('')
  const [departmentId, setDepartmentId] = useState('')
  const [groupName, setGroupName] = useState('')

  useQuery(GetUser, {
    variables: {
      id: cookies.current_organization_id,
    },
    onCompleted: ({ currentUser }) => {
      const listFac = cookies.current_organization_id
        ? currentUser.organizations[0].permittedFacilities
        : currentUser.currentDepartment.organization.permittedFacilities
      setDepartmentId(currentUser.currentDepartment.id)
      setUserId(currentUser.id)
      setIsOrganizer(!!currentUser.organizer)
      setSettings(currentUser.currentDepartment.inhouseOptions)
      setListFacilities(listFac)
      setSelectUserFac(listFac[0].id)
      setOrganizationId(
        cookies.current_organization_id
        || currentUser.currentDepartment.organization.id,
      )
    },
  })

  const { data: subscribeChatNotify } = useSubscription(OnChatNotify, {
    variables: { userId },
  })

  useEffect(() => {
    const targetNode = document.getElementById('modal-message')

    const updateNode = () => {
      if (!targetNode.className.includes('in')) {
        setPanel(MESSAGE_TABS.MESSAGES)
        setSelectedFacility(null)
        setSelectUserFac(null)
        setSelected([])
      }
    }

    updateNode()

    const config = { attributes: true }
    const callback = (mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes'
          && mutation.attributeName === 'class'
        ) {
          updateNode()
        }
      })
    }

    const observer = new MutationObserver(callback)

    observer.observe(targetNode, config)
  }, [])

  useEffect(() => {
    if (
      subscribeChatNotify
      && subscribeChatNotify.onChatNotify
      && subscribeChatNotify.onChatNotify.action !== SOCKET_ACTION_TYPE.DEFAULT
    ) {
      setUpdateObj(subscribeChatNotify.onChatNotify.chatRoom)
    }
  }, [subscribeChatNotify])

  useEffect(() => {
    setSelected([])
  }, [panel])

  return (
    <div className="modal right in" role="dialog" id="modal-message">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {panel === MESSAGE_TABS.MESSAGES && (
            <Messages
              groupMessage={groupMessage}
              organizationId={organizationId}
              switchProfile={!!cookies.current_organization_id}
              listFacilities={listFacilities}
              selectedFacility={selectedFacility}
              setSelectedFacility={setSelectedFacility}
              departmentId={departmentId}
              isOrganizer={isOrganizer}
              userId={userId}
              panel={panel}
              updateObj={updateObj}
              setPanel={setPanel}
              thread={thread}
              setThread={setThread}
            />
          )}
          {panel === MESSAGE_TABS.USERS && (
            <Users
              switchProfile={!!cookies.current_organization_id}
              showTagManagement={showTagManagement}
              departmentId={departmentId}
              organizationId={organizationId}
              selectedFacility={selectedFacility}
              selectUserFac={selectUserFac}
              setSelectUserFac={setSelectUserFac}
              listFacilities={listFacilities}
              isOrganizer={isOrganizer}
              panel={panel}
              setThread={setThread}
              setPanel={setPanel}
              selected={selected}
              setSelected={setSelected}
              settings={settings}
              setSettings={setSettings}
            />
          )}
          {panel === MESSAGE_TABS.GROUPS && (
            <Groups
              groupMessage={groupMessage}
              organizationId={organizationId}
              isOrganizer={isOrganizer}
              switchProfile={!!cookies.current_organization_id}
              panel={panel}
              setPanel={setPanel}
              listFacilities={listFacilities}
              setSelectedFacility={setSelectedFacility}
              selectedFacility={selectedFacility}
              departmentId={departmentId}
              setGroupName={setGroupName}
              setThread={setThread}
            />
          )}
          {panel === MESSAGE_TABS.THREAD && (
            <Thread
              organizationId={organizationId}
              isOrganizer={isOrganizer}
              selectedFacility={selectedFacility}
              updateObj={updateObj}
              userId={userId}
              setPanel={setPanel}
              thread={thread}
              setThread={setThread}
              setNotifyMsg={setNotifyMsg}
            />
          )}
          {panel === MESSAGE_TABS.SETTINGS && (
            <Settings
              groupMessage={groupMessage}
              isOrganizer={isOrganizer}
              settings={settings}
              setSettings={setSettings}
              panel={panel}
              setPanel={setPanel}
            />
          )}
          {panel === MESSAGE_TABS.NEW_GROUP && (
            <NewGroup
              thread={thread}
              setThread={setThread}
              setPanel={setPanel}
              setGroupName={setGroupName}
            />
          )}
          {panel === MESSAGE_TABS.GROUP_USER && (
            <GroupUser
              switchProfile={!!cookies.current_organization_id}
              departmentId={departmentId}
              organizationId={organizationId}
              isOrganizer={isOrganizer}
              selectedFacility={selectedFacility}
              selectUserFac={selectUserFac}
              thread={thread}
              setThread={setThread}
              groupName={groupName}
              setGroupName={setGroupName}
              showTagManagement={showTagManagement}
              setPanel={setPanel}
              setNotifyMsg={setNotifyMsg}
            />
          )}
          {panel === MESSAGE_TABS.GROUP_SETTING && (
            <GroupSetting
              switchProfile={!!cookies.current_organization_id}
              departmentId={departmentId}
              organizationId={organizationId}
              selectedFacility={selectedFacility}
              selectUserFac={selectUserFac}
              thread={thread}
              setThread={setThread}
              groupName={groupName}
              setGroupName={setGroupName}
              setPanel={setPanel}
              setNotifyMsg={setNotifyMsg}
            />
          )}
        </div>
      </div>
    </div>
  )
}
