const numberWithCommas = (x) => {
  const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  return formatter.format(x)
}

export const formattedNumber = (number) => (number % 1 === 0 ? Math.round(number) : number.toFixed(2))

export const formattedCurrency = (amount) => {
  if (!amount) return '$0'
  const formattedAmount = formattedNumber(amount)
  return formattedAmount >= 0 ? `$${formattedAmount}` : `-$${-1 * formattedAmount}`
}

export const formattedCurrencyTransaction = (amount) => {
  if (!amount) return '$0.00'
  return amount >= 0 ? `$${numberWithCommas(amount)}` : `-$${numberWithCommas(-1 * amount)}`
}
