import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, useSubscription } from '@apollo/client'

import GetUser from '../MessagePanel/graphql/queries/getUser.gql'
import OnChatNotify from '../MessagePanel/graphql/subscriptions/OnChatNotify.gql'
import { SOCKET_ACTION_TYPE } from '../../lib/constants'

const useStyles = makeStyles({
  message: {
    cursor: 'pointer',
  },
  badge: {
    marginLeft: '5px',
    backgroundColor: '#00A7A4',
  },
})

export const LinkTab = () => {
  const [userId, setUserId] = useState('')
  const [unread, setUnread] = useState(0)
  const classes = useStyles()

  useQuery(GetUser, {
    onCompleted: ({ currentUser }) => {
      setUserId(currentUser.id)
      setUnread(currentUser.totalUnreadRoom)
    },
  })

  const { data: res } = useSubscription(OnChatNotify, {
    variables: { userId },
  })

  useEffect(() => {
    if (res && res.onChatNotify && res.onChatNotify.action !== SOCKET_ACTION_TYPE.DEFAULT) {
      setUnread(res.onChatNotify.totalUnreadRoom)
    }
  }, [res])

  return (
    <a
      data-turbolinks="false"
      data-target="#modal-message"
      href="#modal-message"
      data-toggle="modal"
      className={classes.message}
    >
      Message
      {unread > 0 && <span className={`badge ${classes.badge}`}>{unread}</span>}
    </a>
  )
}
