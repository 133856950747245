import React, { useState } from 'react'
import { OrderDetails } from './OrderDetails'

export default {
  title: 'ShiftModal/Shift/OrderDetails',
  component: OrderDetails,
}

const Template = (args) => {
  const [contactName, contactNameHandler] = useState(args.contactName)
  const [contactPhone, contactPhoneHandler] = useState(args.contactPhone)
  const [poNumber, poNumberHandler] = useState(args.poNumber)
  const [signupLocation, signupLocationHandler] = useState(args.signupLocation)
  const [orderNotes, orderNotesHandler] = useState(args.orderNotes)

  return (
    <OrderDetails
      contactName={contactName}
      contactNameHandler={contactNameHandler}
      contactPhone={contactPhone}
      contactPhoneHandler={contactPhoneHandler}
      poNumber={poNumber}
      poNumberHandler={poNumberHandler}
      signupLocation={signupLocation}
      signupLocationHandler={signupLocationHandler}
      orderNotes={orderNotes}
      orderNotesHandler={orderNotesHandler}
    />
  )
}

export const Main = Template.bind({})

Main.args = {
  contactName: 'Leia Organa',
  contactPhone: '514 820 2192',
  poNumber: '47-XX92',
  signupLocation: 'Alderaan Space Port',
  orderNotes: 'Please bring your blaster',
}
