import React, { useMemo } from 'react'
import { SplitFactory } from '@splitsoftware/splitio-react'
import { CONSTANTS } from '../lib/constants'

export const SplitFragment = ({ splitKey, children }) => {
  const config = useMemo(() => {
    if (splitKey) {
      return {
        core: {
          authorizationKey: CONSTANTS.SPLIT_CLIENT_KEY,
          key: splitKey,
        },
      }
    }
    return null
  }, [splitKey])
  return config ? <SplitFactory config={config}>{children}</SplitFactory> : children
}
