import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { IconButton } from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import styled from '@emotion/styled'
import { Header } from './Header'
import { NewButton } from './UI/NewButton'
import { FooterWrapper } from './UI/Footer'
import { CHAT_ROOM_TYPE, MESSAGE_TABS } from '../../lib/constants'

import GetGroups from './graphql/queries/getGroups.gql'

const StyledBody = styled.div`
  padding: 0 !important;
`

const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 17px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  &:last-of-type {
    border-bottom: none;
  }
`

const GroupName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .num {
    font-size: 12px;
    color: #828282;
  }
`

export const Groups = ({
  groupMessage,
  organizationId,
  isOrganizer,
  switchProfile,
  panel,
  setPanel,
  listFacilities,
  departmentId,
  setSelectedFacility,
  selectedFacility,
  setGroupName,
  setThread,
}) => {
  const roomsRef = useRef(null)
  const [limit, setLimit] = useState(15)
  const [totalRooms, setTotalRooms] = useState(0)
  const [data, setData] = useState([])

  const { loading } = useQuery(GetGroups, {
    fetchPolicy: 'cache-and-network',
    variables: {
      limit,
      organizationId,
      departmentId: switchProfile ? selectedFacility : departmentId,
      roomTypes: ['GROUP_CHAT'],
    },
    onCompleted: ({ currentUser }) => {
      setTotalRooms(currentUser.availableRooms.pagination.totalCount)
      setData(currentUser.availableRooms.items)
    },
    skip: !organizationId,
  })

  useEffect(() => {
    const { current } = roomsRef
    const loadMore = (e) => {
      const el = e.target
      if (!loading && el.scrollTop + el.clientHeight === el.scrollHeight) {
        setLimit((prev) => prev + 10)
      }
    }
    if (data && data.length < totalRooms) {
      current.addEventListener('scroll', loadMore)
    }
    return () => {
      current.removeEventListener('scroll', loadMore)
    }
  }, [data, loading, totalRooms])

  const onClickGroup = ({ room }) => {
    setGroupName(room.name)
    setPanel(MESSAGE_TABS.GROUP_USER)
    setThread({
      type: CHAT_ROOM_TYPE.GROUP,
      roomId: room.id,
      users: [],
    })
  }

  const onClick = () => {
    setPanel(MESSAGE_TABS.NEW_GROUP)
  }

  const onClickSetting = ({ room }) => {
    setGroupName(room.name)
    setPanel(MESSAGE_TABS.GROUP_SETTING)
    setThread({
      type: CHAT_ROOM_TYPE.GROUP,
      roomId: room.id,
      users: [],
    })
  }

  return (
    <>
      <div className="modal-header">
        <Header
          groupMessage={groupMessage}
          switchProfile={switchProfile}
          isOrganizer={isOrganizer}
          panel={panel}
          setPanel={setPanel}
          listFacilities={listFacilities}
          selectedFacility={selectedFacility}
          setSelectedFacility={setSelectedFacility}
        />
      </div>
      <StyledBody className="modal-body" ref={roomsRef}>
        {data.length > 0
          && [...data].map((room) => (
            <Group key={room.id} onClick={() => onClickGroup({ room })}>
              <GroupName>
                <div>{room.name}</div>
                <div className="num">{`${room.chatParticipants.length} users`}</div>
              </GroupName>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  onClickSetting({ room })
                }}
              >
                <Settings />
              </IconButton>
            </Group>
          ))}
      </StyledBody>
      <div className="modal-footer">
        <FooterWrapper>
          <NewButton text="Create Group" handler={onClick} />
        </FooterWrapper>
      </div>
    </>
  )
}
