import React, { useMemo } from 'react'
import { FormControl, Select, MenuItem } from '@material-ui/core'
import styled from '@emotion/styled'
import { Label } from './Label'

const Styled = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.hasStaff ? 'auto' : 'auto 60px')};
  column-gap: 10px;

  .selections {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 5px;

    .label {
      padding: 0px;
      text-align: left;
    }

    .dropdown {
      padding: 0px;

      div {
        padding: 8px;
      }
    }
  }
`

const Dropdown = styled(Select)`
  padding: 0px;
  div {
    padding: 8px;
  }
`

export const PositionDialog = ({ position, positionHandler, positions, qty, qtyHandler, hasStaff }) => {
  const positionId = useMemo(() => (position ? position.id : ''), [position])
  const positionFor = (id) => positions.find((possiblePosition) => possiblePosition.id === id)
  const positionLocalHandler = ({ target }) => positionHandler(positionFor(target.value))
  const qtyLocalHandler = ({ target }) => qtyHandler(target.value)

  return (
    <Styled hasStaff={hasStaff}>
      <div className="selections">
        <Label>Position</Label>
        <FormControl variant="outlined">
          <Dropdown value={positionId} onChange={positionLocalHandler}>
            {positions.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Dropdown>
        </FormControl>
      </div>

      {!hasStaff && (
        <div className="selections">
          <Label>Qty</Label>
          <FormControl variant="outlined">
            <Dropdown value={qty} onChange={qtyLocalHandler} MenuProps={{ style: { maxHeight: 470, maxWidth: 105 } }}>
              {new Array(100)
                .fill()
                .map((_, id) => id + 1)
                .map((number) => (
                  <MenuItem value={number} key={number}>
                    {number}
                  </MenuItem>
                ))}
            </Dropdown>
          </FormControl>
        </div>
      )}
    </Styled>
  )
}
