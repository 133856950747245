import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  closeButton: {
    padding: 0,
    marginTop: '-5px',
    border: 0,
    backgroundColor: 'transparent',

    '& span': {
      fontSize: '26px',
      color: '#828282',
    },
  },
})

export const CloseButton = () => {
  const classes = useStyles()

  return (
    <button
      className={classes.closeButton}
      aria-label="close"
      data-dismiss="modal"
      data-target="#modal-message"
      type="button"
      id="closeBtn"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  )
}
