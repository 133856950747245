import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import styled from '@emotion/styled'

const Container = styled(Paper)`
  background-color: #E0F5F5;
  padding: 20px;
`

const Label = styled(Typography)`
  color: #00848E;
  font-size: 16px;
  line-height: 18.75px;
  margin-bottom: 10px;
`

const Message = styled(Typography)`
  color: #3E3E4C;
  font-size: 14px;
  line-height: 21px;
`

export const Success = () => (
  <Container>
    <Label>Shifts successfully added.</Label>
    <Message>You can continue to add more shifts or publish the shifts you&apos;ve created.</Message>
  </Container>
)
