import React from 'react'
import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'

import { formattedNumber } from '../helpers/currency'
import query from './TopStaffs.gql'
import StarOn from '../../../assets/images/star-on.png'
import Favorite from '../../../assets/images/favourited.png'
import Logo from '../../../assets/images/logo.png'

const useStyles = makeStyles({
  stars: {
    marginTop: '-2px',
  },

  favouriteContent: {
    position: 'relative',
    right: '4px',
    top: '6px',
    width: '40px',
    height: '40px',
    overflow: 'hidden',
    textAlign: 'center',
    margin: '0 auto',
    float: 'none',
  },

  favouriteIcon: {
    height: 'auto',
    maxWidth: '100%',
    minWidth: '100%',
    margin: '0 -100%',
  },
})

const StaffActionButton = ({ staff, selected, toggleSelection }) => {
  if (staff.shift) {
    return (
      <button className="btn btn-block disabled pull-right" type="button" disabled>
        Accepted
      </button>
    )
  }

  if (staff.invite && staff.invite.declinedAt) {
    return (
      <button className="btn btn-block disabled pull-right" type="button" disabled>
        Declined
      </button>
    )
  }

  if (staff.invite) {
    return (
      <button className="btn btn-block disabled pull-right" type="button" disabled>
        Invited
      </button>
    )
  }

  if (!staff.canInvite) {
    return (
      <button className="btn btn-block btn-warning pull-right" type="button" disabled>
        Booked
      </button>
    )
  }

  if (selected) {
    return (
      <button className="btn btn-block btn-danger pull-right" type="button" onClick={toggleSelection}>
        Uninvite
      </button>
    )
  }

  return (
    <button className="btn btn-block btn-primary pull-right" type="button" onClick={toggleSelection}>
      Invite
    </button>
  )
}

const Staff = ({ staff, selected, toggleSelection }) => {
  const { user } = staff
  const classes = useStyles()
  const roundedRating = Math.round(staff.rating)

  return (
    <div className="font">
      <div className="col-sm-12">
        <div className="request-staff__listing padding-left-5 padding-right-5 cf">
          <div className="col-xs-9">
            <div className="row">
              <div className="col-xs-3 request-staff__listing-items margin-bottom-10 margin-top-10 padding-left-0">
                <div className="img-ctn img-xs img-circle relative">
                  <img src={user.avatar.thumb} alt="avatar" />
                </div>
              </div>
              <div className="col-xs-7 request-staff__listing-items padding-0">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="text__bold margin-top-2 display-inline-block pull-left">
                      {user.firstName}
                      <br />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <div className={classes.stars}>
                      {[...Array(roundedRating)].map((_, i) => {
                        const key = `${staff.id}-rating-${i}`
                        // eslint-disable-next-line react/no-array-index-key
                        return <img key={key} alt="star" className="ovr-star" src={StarOn} />
                      })}
                    </div>
                  </div>
                </div>
                {staff.shifts.totalCount > 0 ? (
                  <div className="row">
                    <div className="col-xs-12">
                      <p className="margin-bottom-0 padding-bottom-0 font-12 padding-top-0">
                        {`${staff.shifts.totalCount} shift${staff.shifts.totalCount === 1 ? '' : 's'}`}
                        {' | '}
                        {`${formattedNumber(staff.totalHours)} hour${staff.totalHours === 1 ? '' : 's'}`}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              {staff.favourited && (
                <div className="col-xs-2 padding-0">
                  <div className={classes.favouriteContent}>
                    <img alt="favourited" src={Favorite} className={classes.favouriteIcon} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-xs-3 margin-top-10 margin-bottom-10 padding-0">
            <StaffActionButton staff={staff} selected={selected} toggleSelection={toggleSelection} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const TopStaffs = ({ position, organizerId, searchQuery, selectedStaffIds, setSelectedStaffIds }) => {
  const variables = position
    ? { positionId: position.id, positionListId: position.positionList.id, organizerId, search: searchQuery }
    : {}

  const { data, loading, error } = useQuery(query, { variables, skip: !position })

  const staffs = (data && data.position && data.position.recommendedInviteStaffs.items) || []
  const toggleSelection = (staffId) => {
    if (selectedStaffIds.includes(staffId)) {
      const staffIds = selectedStaffIds.filter((id) => id !== staffId)
      setSelectedStaffIds(staffIds)
      return
    }

    const staffIds = [staffId, ...selectedStaffIds]
    setSelectedStaffIds(staffIds)
  }

  return (
    <div className="topStaffList">
      {loading || error ? (
        <div className="loader-wrapper">
          <div className="loader" />
          <div className="logo">
            <img alt="logo" src={Logo} />
          </div>
        </div>
      ) : (
        staffs.map((staff) => (
          <Staff
            key={staff.id}
            staff={staff}
            selected={selectedStaffIds.includes(staff.id)}
            toggleSelection={() => toggleSelection(staff.id)}
          />
        ))
      )}
    </div>
  )
}
