import React from 'react'
import { NotesDialog } from './NotesDialog'

export default {
  title: 'ShiftModal/Dialog/Notes',
  component: NotesDialog,
}

const Template = () => <NotesDialog />
export const Main = Template.bind({})
