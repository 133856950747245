import React, { useState } from 'react'
import { PositionDialog } from './PositionDialog'

export default {
  title: 'ShiftModal/Dialog/Position',
  component: PositionDialog,
  args: {
    qty: 1,
  },
}

const Template = (args) => {
  const positions = [
    { id: 0, name: 'Chef' },
    { id: 1, name: 'Nurse' },
    { id: 2, name: 'Bartender' },
  ]

  const [position, positionHandler] = useState(positions[0])
  const [qty, qtyHandler] = useState(args.qty)

  return (
    <PositionDialog
      position={position}
      positions={positions}
      positionHandler={positionHandler}
      qty={qty}
      qtyHandler={qtyHandler}
    />
  )
}

export const Main = Template.bind({})
