import React, { useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { TextareaAutosize } from '@material-ui/core'

import SendMessage from '../graphql/mutations/sendMessage.gql'
import { MESSAGE_STATUS } from '../../../lib/constants'

const useStyles = makeStyles({
  messageBox: {
    borderRadius: '4px',
  },
})

const Textarea = styled(TextareaAutosize)`
  background-color: #fff !important;
`

const TextBox = styled.div`
  display: relative;
`

export const MessageBox = ({
  thread,
  setData,
  totalMessages,
  setTotalMessages,
  setNotifyMsg,
  messagesRef,
}) => {
  const field = useRef(null)
  const classes = useStyles()
  const [content, setContent] = useState('')

  const updateContent = (event) => {
    setContent(event.target.value)
  }

  const [sendMessage, { loading }] = useMutation(SendMessage, {
    onCompleted: ({ sendMessage: res }) => {
      if (res.errors.length > 0) {
        setNotifyMsg({ message: res.errors[0].message, type: 'error' })
      } else {
        setContent('')
        setData((prev) => [
          ...prev,
          {
            ...res.chatRoom.chatMessages.items[0],
            status: res.chatRoom.chatMessages.items[0].readAt
              ? MESSAGE_STATUS.READ
              : MESSAGE_STATUS.RECEIVED,
          },
        ])
        setTotalMessages(totalMessages + 1)
        messagesRef.current.scrollTo({
          top: messagesRef.current.scrollHeight,
          behavior: 'smooth',
        })
      }
    },
  })

  const submitMessage = () => {
    const variables = {
      content,
      roomId: thread.roomId,
      participantIds: thread.users.map((u) => u.id),
      departmentId: thread.departmentId,
    }
    if (!variables.roomId) {
      delete variables.roomId
    } else {
      delete variables.participantIds
    }
    sendMessage({
      variables,
    })
  }

  const sendOnEnter = (event) => {
    if (event.keyCode === 13 && !event.shiftKey && content.trim()) {
      event.preventDefault()
      submitMessage()
    }
  }

  return (
    <div className="row">
      <TextBox className="col-md-12">
        <Textarea
          disabled={loading}
          ref={field}
          className={`${classes.messageBox} form-control`}
          value={content}
          minRows={3}
          onChange={updateContent}
          onKeyDown={sendOnEnter}
        />
      </TextBox>
    </div>
  )
}
