import React, { useEffect, useMemo, useState } from 'react'
import { Button } from '@material-ui/core'
import styled from '@emotion/styled'
import DoneIcon from '@material-ui/icons/Done'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import dayjs from 'dayjs'
import { toDate, toTime } from '../../helpers/time'
import {
  MESSAGE_STATUS,
  MESSAGE_TYPE,
  REGEX_VALIDATION,
  capitalizeFirstLetter,
} from '../../../lib/constants'
import { Avatar } from '../components/Avatar'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .img-ctn {
    margin: 0;
    margin-right: 5px;
  }
`
const Box = styled.div`
  width: fit-content;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  &.boxReceived {
    background-color: #bdbdbd;
    max-width: 100%;
  }
  &.boxSent {
    background-color: #f2f2f2;
    margin: 0 0 10px auto;
    text-align: right;
    max-width: 75%;
  }
  &.boxSystem {
    font-size: 14px;
    padding: 5px 10px;
    color: #828282;
    background-color: #fff;
    width: 335px;
    margin: 30px auto;
    div {
      text-align: center;
    }
  }
`
const Content = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
  &.sent {
    text-align: left;
  }
`
const SentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  &.timeSent {
    justify-content: end;
  }
`
const ReadWrapper = styled.div`
  text-align: center;
  color: #828282;
  flex-basis: 100%;
`
const ReceivedBox = styled.div`
  max-width: 75%;
`

const ExpandBtn = styled(Button)`
  padding: 0;
  margin-bottom: 5px;
  text-transform: none;
  height: 12px;
`

export const Message = ({ roomId, userId, message, markRead, isLast }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { readAt, createdAt, user, status, content, messageType } = message
  const isReceived = user.id !== userId
  const unread = isReceived && !readAt

  useEffect(() => {
    if (roomId && unread) {
      markRead({ variables: { roomId } })
    }
  }, [markRead, roomId, unread])

  const boxClassName = useMemo(() => {
    if (messageType === MESSAGE_TYPE.SYSTEM) {
      return 'boxSystem'
    }
    if (isReceived) {
      return 'boxReceived'
    }
    return 'boxSent'
  }, [isReceived, messageType])

  const displayTimer = useMemo(
    () => isLast && !isReceived && readAt && messageType !== MESSAGE_TYPE.SYSTEM,
    [isLast, isReceived, messageType, readAt],
  )

  const userMessage = useMemo(() => (
    <Box className={boxClassName}>
      <Content className={isReceived ? 'received' : 'sent'}>
        {content.split('\n').map((text) => (
          <div key={text}>
            {text.split(REGEX_VALIDATION.IS_LINK).map((txt) => (
              <span key={txt}>
                {REGEX_VALIDATION.IS_LINK.test(txt) ? (
                  <>
                    <a
                      href={
                        REGEX_VALIDATION.HAS_HEADER.test(txt)
                          ? txt
                          : `http://${txt}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {txt}
                    </a>
                    {' '}
                  </>
                ) : (
                  <>
                    {txt}
                    {' '}
                  </>
                )}
              </span>
            ))}
          </div>
        ))}
      </Content>
      <SentWrapper className={isReceived ? 'timeReceived' : 'timeSent'}>
        <div>{toTime(createdAt)}</div>
        {!isReceived
          && (status === MESSAGE_STATUS.SENT ? (
            <DoneIcon color="disabled" />
          ) : (
            <DoneAllIcon
              color={
                status === MESSAGE_STATUS.READ ? 'primary' : 'disabled'
              }
            />
          ))}
      </SentWrapper>
    </Box>
  ), [boxClassName, content, createdAt, isReceived, status])

  return (
    <Container>
      {messageType !== MESSAGE_TYPE.SYSTEM && isReceived && (
        <Avatar thumbnail={user.avatar.thumb} name={user.fullName} />
      )}
      {messageType === MESSAGE_TYPE.SYSTEM ? (
        <Box className={boxClassName}>
          <Content className={isReceived ? 'received' : 'sent'}>
            <div>
              <span>
                {capitalizeFirstLetter(
                  isExpanded
                    ? content
                    : `${content.slice(0, 200)}${
                      content.length > 200 ? '...' : ''
                    }`,
                )}
              </span>
              {content.length > 200 && (
                <ExpandBtn
                  variant="text"
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? 'See less' : 'See more'}
                </ExpandBtn>
              )}
            </div>
          </Content>
        </Box>
      ) : (
        <>
          {isReceived ? (
            <ReceivedBox>
              <div>{user.fullName}</div>
              {userMessage}
            </ReceivedBox>
          ) : (
            userMessage
          )}
        </>
      )}
      {displayTimer && (
        <ReadWrapper>
          {`Read ${toDate(readAt)} at ${dayjs(readAt).format(
            'hh:mm a',
          )} `}
        </ReadWrapper>
      )}
    </Container>
  )
}
