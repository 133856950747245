import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { Drawer } from '@material-ui/core'
import styled from '@emotion/styled'
import { toTime } from '../helpers/time'
import { StaffNameAutosuggest } from './StaffNameAutosuggest'
import { TopStaffs } from './TopStaffs'
import query from './PositionInviteModal.gql'

const INVITE_TO_PICK_UP_RATIO = 3

const DrawerStyled = styled(Drawer)(
  ({ theme }) => `
  .MuiDrawer-paper {
    width: 450px;
    ${theme.breakpoints.only('xs')} {
      width: 100vw;
    }
  }

  .modal-header {
    flex: none;
  }

  .modal-body {
    flex: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    #staff-list {
      overflow-y: auto;

      .loader-wrapper{
        height: 150px;
      }
    }

    #staff-list::-webkit-scrollbar{
      width: 20px;
      height: 18px;
    }
    #staff-list::-webkit-scrollbar-thumb {
      height: 6px;
      width: 12px;
      border: 5px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 15px;
      background-color: #DDDDDD;
      -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    #staff-list::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    #staff-list::-webkit-scrollbar-corner{
      background-color: transparent;
    }
  }

  .modal-footer {
    flex: none;
    position: relative;
  }
`,
)

export const PositionInviteModal = ({ open, setOpen, positionId }) => {
  const [searchQuery, setSearchQuery] = useState(null)
  const [selectedStaffIds, setSelectedStaffIds] = useState([])

  const { loading, error, data } = useQuery(query, { variables: { positionId } })
  const position = data && data.position
  if (!position && !loading && !error) {
    return (
      <DrawerStyled anchor="right" open={open} onClose={() => setOpen(false)}>
        <div className="modal-header">
          <h5 className="modal-title">Cannot invite to past events</h5>
        </div>
        <div className="modal-body">
          <p>The event you&apos;re trying to invite staff to either already happened or is happening too soon.</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setOpen(false)}>
            Close
          </button>
        </div>
      </DrawerStyled>
    )
  }
  const organizerId = data && data.currentUser && data.currentUser.organizer && data.currentUser.organizer.organizerId

  const submit = () => {
    const referer = new URL(window.location)
    const { searchParams } = referer

    searchParams.append('utf8', '✓')
    searchParams.append('commit', 'Invite+Staff')
    searchParams.append('position_for_invitation', position.id)
    selectedStaffIds.forEach((staffId) => {
      searchParams.append(`invite_${staffId}`, '1')
    })

    window.location.href = `${referer.origin}${referer.pathname}?${searchParams.toString()}`
  }

  return (
    <DrawerStyled anchor="right" open={open} onClose={() => setOpen(false)}>
      <div className="modal-header">
        <button className="close" data-dismiss="modal" type="button" onClick={() => setOpen(false)}>
          <span>×</span>
        </button>
        <h4 className="modal-title">
          Invite Staff&nbsp;
          {position && (
            <div className="shift-capsule pull-none display-inline font-14">
              {`${position.name} : ${toTime(position.startTime)} - ${toTime(position.endTime)}`}
            </div>
          )}
        </h4>
      </div>
      <div className="modal-body">
        <p className="small">
          Please note that when you invite staff to a set of shifts (like this one), no other staff will be able to pick
          up shifts in that set. Thus, for this set of shifts please invite at least
          <span>{position ? ` ${position.listShifts.totalCount * INVITE_TO_PICK_UP_RATIO} ` : ' _ '}</span>
          staff so that these shifts get fully staffed. Again, only these invited staff will have access to this set of
          shifts and will be able to pick them up.
          <br />
          You may also make this set of shifts public later on (allowing others to pick up shifts in this set).
        </p>
        <div className="row margin-top-15">
          <div className="col-sm-12">
            <StaffNameAutosuggest positionId={positionId} setSearchQuery={setSearchQuery} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h5 className="text__bold">Top Staff</h5>
            <hr className="hyrestaff-hr" />
          </div>
        </div>
        <div className="row" id="staff-list">
          <div className="col-xs-12 padding-0">
            <TopStaffs
              position={position}
              organizerId={organizerId}
              searchQuery={searchQuery}
              selectedStaffIds={selectedStaffIds}
              setSelectedStaffIds={setSelectedStaffIds}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="col-sm-10 col-sm-offset-1 modal-main-btn">
          <button
            className="btn btn-primary btn-block"
            type="submit"
            disabled={selectedStaffIds.length === 0}
            onClick={submit}
          >
            Invite Staff
          </button>
        </div>
      </div>
    </DrawerStyled>
  )
}
