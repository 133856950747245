import styled from '@emotion/styled'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react'

const StyledDialog = styled(Dialog)`
  &.shakeModal {
    & .MuiDialog-paper {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
    }
  }
  & .MuiDialog-paper {
    width: ${(props) => (props.width ? `${props.width}px` : '400px')};
  }
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`

const ImportantButton = styled(Button)`
  text-transform: uppercase;
  background-color: #ecf0f1;
  color: #000;
  width: auto;
  &:hover {
    background-color: #ddd;
    border-color: #ddd;
    box-shadow: none;
  }
`

export const ImportantDialog = ({
  title,
  width,
  content,
  open,
  onCancel,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}) => {
  const [backdropClick, setBackdropClick] = useState(false)

  const handleCancel = () => {
    setBackdropClick(false)
    onCancel()
  }

  const handleShakeModal = () => {
    setBackdropClick(true)
    setTimeout(() => {
      setBackdropClick(false)
    }, 800)
  }

  return (
    <StyledDialog width={width} className={backdropClick ? 'shakeModal' : ''} open={open} onClose={handleShakeModal}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <div>{content}</div>
      </DialogContent>
      <DialogActions>
        <ImportantButton onClick={handleCancel} variant="contained">
          {cancelText}
        </ImportantButton>
        <ImportantButton fullWidth onClick={onConfirm} variant="contained">
          {confirmText}
        </ImportantButton>
      </DialogActions>
    </StyledDialog>
  )
}
