import React, { useState } from 'react'
import { TimeDialog } from './TimeDialog'

export default {
  title: 'ShiftModal/Dialog/Time',
  component: TimeDialog,
  argTypes: {
  },
  args: {
    startAt: '09:00',
    endAt: '17:00',
    breakDuration: 15,
  },
}

const Template = (args) => {
  const [startAt, startAtHandler] = useState(args.startAt)
  const [endAt, endAtHandler] = useState(args.endAt)
  const [breakDuration, breakDurationHandler] = useState(args.breakDuration)
  const [duration, durationHandler] = useState(8)

  return (
    <TimeDialog
      startAt={startAt}
      startAtHandler={startAtHandler}
      endAt={endAt}
      endAtHandler={endAtHandler}
      breakDuration={breakDuration}
      breakDurationHandler={breakDurationHandler}
      duration={duration}
      durationHandler={durationHandler}
    />
  )
}

export const Main = Template.bind({})
