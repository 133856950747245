import React from 'react'
import styled from '@emotion/styled'
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { TagDropdown } from '../TagDropdown'
import { WageField } from '../CreatePosition/ModalItems'
import { LabelDropdown } from '../LabelDropdown'

const StyledSelect = styled(Select)`
  padding: 0px;
  div {
    font-size: 14px;
    padding: 8px;
  }
`

const Label = styled.div`
  margin-bottom: 10px;
`

const TagLabel = styled(Label)`
  display: flex;
  align-items: center;
  gap: 5px;
`

const shiftTypes = [
  { id: 1, label: 'Assign Only', value: true },
  { id: 2, label: 'Eligible staff can pickup', value: false },
]

export const Options = ({
  handler,
  shift,
  shiftIndex,
  departments,
  staffDepartments,
  hasStaff,
  isFacility,
  showTagManagement,
  dynamicStaffWage,
}) => {
  const { shiftType, department, tags, wage: staffWage, labels } = shift
  const updateShiftType = ({ target }) => handler({ ...shift, ...{ shiftType: target.value } }, shiftIndex)
  const updateDepartment = ({ target }) => handler({ ...shift, ...{ department: target.value } }, shiftIndex)
  const updateTags = (value) => handler({ ...shift, ...{ tags: value } }, shiftIndex)
  const updateLabels = (value) => handler({ ...shift, ...{ labels: value } }, shiftIndex)
  const updateDynamicStaffWage = (value) => {
    let val
    if (typeof value === 'object' && value !== null) {
      val = value.target.value
    } else {
      val = value
    }
    handler(
      { ...shift, ...{ wage: val === null || val === '' ? '' : Number(val) } },
      shiftIndex,
    )
  }
  const listDepartments = hasStaff ? staffDepartments : departments
  return (
    <Grid container spacing={2}>
      {isFacility && (
        <Grid item xs={4}>
          <Label>Department</Label>
          <FormControl fullWidth variant="outlined">
            <StyledSelect
              value={department}
              displayEmpty
              onChange={updateDepartment}
            >
              <MenuItem value={null} disabled>
                Select department
              </MenuItem>
              {listDepartments.map((dpm) => (
                <MenuItem key={dpm.id} value={dpm.id}>
                  {dpm.name}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Grid>
      )}
      {isFacility && !dynamicStaffWage && <Grid item xs={2} />}
      {shift.type !== 'agency' && !hasStaff && (
        <Grid item xs={4}>
          <Label>Shift Type</Label>
          <FormControl fullWidth variant="outlined">
            <StyledSelect onChange={updateShiftType} value={shiftType}>
              {shiftTypes.map((type) => (
                <MenuItem key={type.id} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Grid>
      )}
      {!isFacility && dynamicStaffWage && <Grid item xs={2} />}
      {shift.type === 'inHouse' && dynamicStaffWage && (
        <Grid item xs={4}>
          <Label>Staff Pay Rate/hr</Label>
          <WageField
            value={staffWage}
            onChange={updateDynamicStaffWage}
            min={0}
            nullable
          />
        </Grid>
      )}
      {shift.type === 'inHouse' && (
        <>
          {showTagManagement && (
            <Grid item xs={4}>
              <TagLabel>
                <div>Add tags (optional)</div>
                <Tooltip title="Only users with these tags can see these shifts">
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </TagLabel>
              <TagDropdown
                tags={tags || []}
                setTags={updateTags}
                type="staff"
              />
            </Grid>
          )}
          {((!isFacility && dynamicStaffWage)
            || (isFacility && !dynamicStaffWage)) && <Grid item xs={2} />}
          <Grid item xs={4}>
            <Label>Add labels (optional)</Label>
            <LabelDropdown labels={labels || []} setLabels={updateLabels} />
          </Grid>
        </>
      )}
    </Grid>
  )
}
