import React, { useState, useEffect, useMemo } from 'react'
import { useSubscription } from '@apollo/client'
import { MenuItem, Select } from '@material-ui/core'
import styled from '@emotion/styled'
import _ from 'lodash'
import { User } from './Users/User'
import { NewButton } from './UI/NewButton'
import { CHAT_ROOM_TYPE, MESSAGE_TABS } from '../../lib/constants'
import { Header } from './UI/Header'
import { SearchUser } from './Users/SearchUser'
import { UserSelected } from './Users/UserSelected'

import OnDepartmentSettings from './graphql/subscriptions/OnDepartmentSettings.gql'

const ModalBody = styled.div(
  ({ selected }) => `
  height: calc(100vh - ${selected ? '160px' : '135px'}) !important;
`,
)

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

const ButtonWrapper = styled.div`
  text-align: center;
`

const StyledSelect = styled(Select)`
  margin: 0 15px;
  width: calc(100% - 15px);
  .MuiSelect-root {
    padding: 6px 12px;
  }
`

export const Users = ({
  isOrganizer,
  showTagManagement,
  switchProfile,
  departmentId,
  setThread,
  organizationId,
  selectedFacility,
  selectUserFac,
  setSelectUserFac,
  listFacilities,
  setPanel,
  selected,
  setSelected,
  settings,
  setSettings,
}) => {
  const facId = selectedFacility || selectUserFac
  const [members, setMembers] = useState([])
  const handleSelect = ({ target }) => {
    if (target.checked) {
      setSelected(members)
    } else {
      setSelected([])
    }
  }

  const selectAll = useMemo(() => selected.length === members.length, [
    selected,
    members,
  ])

  const { data: subscribeDeptSettings } = useSubscription(
    OnDepartmentSettings,
    {
      variables: { departmentId: facId },
      skip: isOrganizer,
    },
  )

  useEffect(() => {
    if (_.get(subscribeDeptSettings, 'onDepartmentSettings.department')) {
      setSettings(
        subscribeDeptSettings.onDepartmentSettings.department.inhouseOptions,
      )
    }
  }, [setSettings, subscribeDeptSettings])

  const handleBack = () => {
    setSelectUserFac(listFacilities[0].id)
    setPanel(MESSAGE_TABS.MESSAGES)
  }

  const handleNewMessage = () => {
    setThread({
      type:
        selected.length === 1
          ? CHAT_ROOM_TYPE.DIRECT
          : CHAT_ROOM_TYPE.BROADCAST,
      users: selected,
      departmentId: switchProfile ? facId : departmentId,
    })
    setPanel('thread')
  }

  const handleGroupMessage = () => {
    setThread({
      type: CHAT_ROOM_TYPE.GROUP,
      roomId: null,
      users: selected,
    })
    setPanel(MESSAGE_TABS.NEW_GROUP)
  }

  return (
    <>
      <div className="modal-header">
        <Header tab={MESSAGE_TABS.USERS} setPanel={handleBack} />
      </div>
      <ModalBody className="modal-body" selected={selected.length > 0}>
        {switchProfile && !selectedFacility && (
          <StyledSelect
            labelId="selectFacility"
            id="selectFacilityDropdown"
            value={selectUserFac}
            variant="outlined"
            fullWidth
            onChange={({ target }) => setSelectUserFac(target.value)}
          >
            {listFacilities.map((fac) => (
              <MenuItem key={fac.id} value={fac.id}>
                {fac.name}
              </MenuItem>
            ))}
          </StyledSelect>
        )}
        <SearchUser
          departmentId={switchProfile ? facId : departmentId}
          organizationId={organizationId}
          showTagManagement={showTagManagement}
          isOrganizer={isOrganizer}
          selectAll={selectAll}
          handleSelect={handleSelect}
          setMembers={setMembers}
          settings={settings}
        />
        {members.length > 0
          && members.map((user) => (
            <User
              key={user.id}
              departmentId={departmentId}
              switchProfile={switchProfile}
              user={user}
              facId={facId}
              isOrganizer={isOrganizer}
              setThread={setThread}
              setPanel={setPanel}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
      </ModalBody>
      <UserSelected selected={selected} />
      <ModalFooter className="modal-footer">
        {isOrganizer && selected.length > 0 && (
          <>
            <ButtonWrapper>
              <NewButton
                text={selected.length === 1 ? 'New Message' : 'New Broadcast'}
                handler={handleNewMessage}
              />
            </ButtonWrapper>
            {selected.length > 1 && (
              <ButtonWrapper>
                <NewButton
                  text="Group Message"
                  handler={handleGroupMessage}
                />
              </ButtonWrapper>
            )}
          </>
        )}
      </ModalFooter>
    </>
  )
}
