import React, { useEffect, useState } from 'react'
import { Alert, Snackbar } from '@material-ui/core'
import styled from '@emotion/styled'

const NotifyContainer = styled(Snackbar)`
  width: 100%;
  display: block;
`

const Notify = styled(Alert)`
  justify-content: center;
  align-items: center;
  border-radius: 0;
  height: 70px;
  background-color: ${(props) => {
    switch (props.backgroundcolor) {
    case 'success':
      return '#00a7a4'
    case 'error':
      return '#dd4b39'
    default:
      return '#fff'
    }
  }};
  .MuiAlert-action {
    margin-left: 0;
  }
  span {
    font-weight: bold;
    color: #fff;
  }
`

export const NotifyMessage = ({ onClose, msg }) => {
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState(null)
  const [type, setType] = useState(null)

  useEffect(() => {
    if (msg.message && msg.type) {
      setMessage(msg.message)
      setType(msg.type)
      setShow(true)
    }
  }, [msg])

  useEffect(() => {
    if (!show) {
      setMessage('')
      setType('')
    }
  }, [show])

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    setShow(false)
  }

  return (
    <NotifyContainer
      open={show}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Notify variant="filled" backgroundcolor={type} onClose={handleClose} icon={false}>
        {message}
      </Notify>
    </NotifyContainer>
  )
}
