import React from 'react'
import { CookiesProvider, useCookies } from 'react-cookie'
import { createConsumer } from '@rails/actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { split, createHttpLink as CreateHttpLink, InMemoryCache, ApolloClient, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { LinkTab } from './MessageLink/LinkTab'
import { CONSTANTS } from '../lib/constants'

const MessageLink = () => {
  const [cookies] = useCookies(['jwt_token'])
  const token = cookies.jwt_token
  const webHeader = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }))
  const cable = createConsumer(`${CONSTANTS.SOCKET_HOST}${window.location.host}/cable`)
  const actionCableLink = new ActionCableLink({ cable })
  const httpLink = webHeader.concat(
    new CreateHttpLink({
      uri: '/apisite/v1/graphql',
    }),
  )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    actionCableLink,
    httpLink,
  )

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return (
    <ApolloProvider client={client}>
      <CookiesProvider>
        <LinkTab />
      </CookiesProvider>
    </ApolloProvider>
  )
}

export default MessageLink
