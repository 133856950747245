import React from 'react'

const RecurringOff = ({ fill, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 5.27L3.28 4L20 20.72L18.73 22L15.73 19H7V22L3 18L7 14V17H13.73L7
        10.27V11H5V8.27L2 5.27ZM17 13H19V17.18L17 15.18V13ZM17 5V2L21 6L17 10V7H8.82L6.82 5H17Z"
      fill={fill}
    />
  </svg>
)

export default RecurringOff
