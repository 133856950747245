import React from 'react'
import { Actions } from './Actions'

export default {
  title: 'ShiftModal/Shift/Actions',
  component: Actions,
  argTypes: {
    draft: { action: 'save draft' },
    post: { action: 'post shift' },
    enabled: {
      control: { type: 'boolean' },
      defaultValue: true,
    },
  },
}

const Template = ({ draft, post, enabled }) => (
  <Actions
    draftHandler={draft}
    postHandler={post}
    enabled={enabled}
  />
)

export const Main = Template.bind({})
