import React, { useState } from 'react'
import styled from '@emotion/styled'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { Checkbox, Divider, ListItemText, Menu, MenuItem, Select } from '@material-ui/core'
import { SimpleButton } from './StyledButton'
import { capitalize } from '../../../lib/constants'

const FilterButton = styled(SimpleButton)`
  border-color: #c4cdd5;
  justify-content: space-between;
  padding: 9px;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  background-color: #F4F6F8;
`

const FilterBody = styled.div`
  width: 260px;
  padding: 10px;
`

const FilterLabel = styled.div`
  color: #637381;
  font-weight: 600;
`

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    background-color: #F4F6F8;
    padding: 7px 12px;
    font-size: 14px;
    &.Mui-disabled {
      -webkit-text-fill-color: #919eab;
    }
  }
`

const Item = styled(MenuItem)`
  gap: 8px;
`

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`

const Separate = styled(Divider)`
  margin: 1em 0;
`

export const FilterDropdown = ({
  idBtn,
  idMenu,
  title = 'Filter By',
  options,
  value,
  handleOnChangeFilter,
  children,
}) => {
  const [filterEl, setFilterEl] = useState(null)

  const handleOpenFilter = (event) => {
    setFilterEl(filterEl ? null : event.currentTarget)
  }

  const handleCloseFilter = () => {
    setFilterEl(null)
  }
  return (
    <>
      <FilterButton
        id={idBtn}
        fullWidth
        variant="outlined"
        color="inherit"
        onClick={handleOpenFilter}
        endIcon={filterEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        {title}
      </FilterButton>
      <Menu
        id={idMenu}
        keepMounted
        onClose={handleCloseFilter}
        open={Boolean(filterEl)}
        anchorEl={filterEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FilterBody>
          {options
            && options.map((opt, index) => (
              <div key={opt.id}>
                {opt.showName ? <FilterLabel>{opt.name.toUpperCase()}</FilterLabel> : ''}
                <StyledSelect
                  variant="outlined"
                  id="filterStatus"
                  fullWidth
                  value={value[opt.dataType]}
                  multiple
                  onChange={handleOnChangeFilter(
                    opt.options.map((option) => option[opt.selectedType]),
                    opt.dataType,
                  )}
                  renderValue={(selected) => {
                    const name = []
                    selected.map((sel) => name
                      .push(opt.options.find((item) => item[opt.selectedType] === sel)[opt.optionText]))
                    if (opt.checkAllText && name.length === opt.options.length) {
                      return opt.checkAllText
                    }
                    return name.join(', ')
                  }}
                >
                  {opt.checkAllText && (
                    <Item value="all">
                      <StyledCheckbox color="primary" checked={value[opt.dataType].length === opt.options.length} />
                      <ListItemText primary={opt.checkAllText} />
                    </Item>
                  )}
                  {opt.options.length > 0
                    && opt.options.map((option) => (
                      <Item key={option.id} value={option[opt.selectedType]}>
                        <StyledCheckbox
                          color="primary"
                          checked={value[opt.dataType].includes(option[opt.selectedType])}
                        />
                        <ListItemText primary={capitalize(option[opt.optionText])} />
                      </Item>
                    ))}
                </StyledSelect>
                {index === options.length - 1 ? '' : <Separate />}
              </div>
            ))}
          {children}
        </FilterBody>
      </Menu>
    </>
  )
}
