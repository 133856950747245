import React from 'react'
import styled from '@emotion/styled'

const Button = styled.button`
  background-color: #828282;
  border-radius: 4px;
  color: #fff;
  border: 0;
  padding: 10px 50px;
  :hover {
    color: #fff;
  }
  :disabled {
    opacity: 0.4;
  }
`
export const NewButton = ({ text, disabled, handler }) => (
  <Button disabled={disabled} onClick={handler}>
    {text}
  </Button>
)
