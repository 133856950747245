import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import Autosuggest from 'react-autosuggest'
import { makeStyles } from '@material-ui/core/styles'

import query from './StaffNameAutosuggest.gql'

const MIN_SEARCH_QUERY_LENGTH = 2

const useStyles = makeStyles({
  input: {
    border: '1px solid #aaa',
    borderRadius: '3px',
    display: ' block',
    width: ' 100%',
    height: ' 38px',
    padding: ' 6px 12px',
    fontSize: ' 14px',
    lineHeight: ' 1.428571429',
    color: '#555555',
    backgroundColor: ' #fff',
    backgroundImage: ' none',
    boxShadow: ' inset 0 1px 1px rgba(0,0,0,0.075)',
    transition: ' border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s',
  },

  suggestionsContainerOpen: {
    top: ' 34px',
    left: ' 15px',
    display: ' block',
    position: ' absolute',
    zIndex: ' 1000',
    float: ' left',
    minWidth: ' 160px',
    padding: ' 5px 0',
    margin: ' 2px 0 0',
    fontSize: ' 14px',
    textAlign: ' left',
    listStyle: ' none',
    backgroundColor: ' #fff',
    backgroundClip: ' padding-box',
    border: ' 1px solid rgba(0,0,0,0.15)',
    borderRadius: ' 4px',
    boxShadow: ' 0 6px 12px rgba(0,0,0,0.175)',
  },

  suggestion: {
    display: ' block',
    padding: ' 3px 20px',
    clear: ' both',
    fontWeight: ' 400',
    lineHeight: ' 1.428571429',
    color: ' #333333',
    whiteSpace: ' nowrap',
  },

  suggestionHighlighted: {
    color: ' #fff',
    textDecoration: ' none',
    backgroundColor: ' #337ab7',
    outline: ' 0',
    display: ' block',
    padding: ' 3px 20px',
    clear: ' both',
    fontWeight: ' 400',
    lineHeight: ' 1.428571429',
    whiteSpace: ' nowrap',
    cursor: ' pointer',
  },

  suggestionsList: {
    paddingLeft: ' 0',
    marginBottom: ' 0',
  },
})

export const StaffNameAutosuggest = ({ positionId, setSearchQuery }) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  const { data } = useQuery(query, {
    variables: { positionId, search: inputValue },
    skip: !inputValue || inputValue.length < MIN_SEARCH_QUERY_LENGTH,
  })

  const getSuggestionValue = (suggestion) => suggestion.name
  const onChange = (_event, { newValue }) => {
    setInputValue(newValue)
    if (!newValue) setSearchQuery(null)
  }
  const onSuggestionSelected = (_event, { suggestion }) => {
    setInputValue(suggestion)
    setSearchQuery(suggestion)
  }
  const renderSuggestion = (suggestion) => <div>{suggestion}</div>

  const suggestions = data
    && data.position
    && data.position.recommendedInviteStaffs.items.map((staff) => {
      const { firstName, lastName } = staff.user

      if (firstName.toLocaleLowerCase().startsWith(inputValue.toLocaleLowerCase())) {
        return firstName
      }

      if (lastName.toLocaleLowerCase().startsWith(inputValue.toLocaleLowerCase())) {
        return lastName
      }

      return null
    })

  return (
    <Autosuggest
      suggestions={suggestions ? [...new Set(suggestions)] : []}
      onSuggestionsFetchRequested={() => {}}
      onSuggestionsClearRequested={() => {}}
      getSuggestionValue={getSuggestionValue}
      onSuggestionSelected={onSuggestionSelected}
      renderSuggestion={renderSuggestion}
      theme={{
        input: classes.input,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
        suggestionHighlighted: classes.suggestionHighlighted,
      }}
      inputProps={{
        placeholder: 'Search by first or last name...',
        value: inputValue,
        onChange,
      }}
    />
  )
}
