import React from 'react'
import { Grid, Tab, Tabs } from '@material-ui/core'
import styled from '@emotion/styled'
import { Settings } from '@material-ui/icons'
import { CloseButton } from './UI/CloseButton'
import { HeaderLink } from './UI/HeaderLink'

const Container = styled.div`
  background: #f5f5f5;
`

const DepartmentWrapper = styled(Grid)`
  margin-bottom: 8px;
`

const Close = styled(Grid)`
  text-align: 'right';
`

const SettingTab = styled(HeaderLink)`
  height: 24px;
`

const FlexGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const StyledTabs = styled(Tabs)`
  min-height: 0px;
  .MuiTabs-flexContainer {
    gap: 4px;
  }
  .MuiTabs-indicator {
    display: none;
  }
`

const StyledTab = styled(Tab)`
  &.MuiButtonBase-root {
    min-width: 0;
    border-radius: 4px;
    min-height: 0;
    text-transform: none;
    background-color: #fff;
    color: #333;
    &.selected {
      background-color: #00848e;
      color: #fff;
      &:hover {
        background-color: #00656d;
      }
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`

const ListDept = styled(FlexGrid)`
  gap: 1em;
`

export const Header = ({
  groupMessage,
  isOrganizer,
  switchProfile,
  panel,
  setPanel,
  listFacilities,
  selectedFacility,
  setSelectedFacility,
}) => {
  const handleChangeFacility = (event, newValue) => {
    setSelectedFacility(newValue)
  }
  return (
    <Container>
      {switchProfile ? (
        <>
          <DepartmentWrapper container spacing={1}>
            <ListDept item xs={11}>
              <StyledTabs
                value={selectedFacility}
                onChange={handleChangeFacility}
                variant="scrollable"
                scrollButtons="auto"
              >
                <StyledTab
                  className={`${selectedFacility === null ? 'selected' : ''}`}
                  value={null}
                  label="All chats"
                />
                {listFacilities.length > 1
                  && listFacilities.map((fac) => (
                    <StyledTab
                      key={fac.id}
                      className={`${selectedFacility === fac.id ? 'selected' : ''}`}
                      value={fac.id}
                      label={fac.name}
                    />
                  ))}
              </StyledTabs>
            </ListDept>
            <Close item x={1} textAlign="right">
              <CloseButton />
            </Close>
          </DepartmentWrapper>
          <Grid container spacing={1}>
            <FlexGrid item xs={12}>
              <HeaderLink label="Messages" id="messages" panel={panel} setPanel={setPanel} />
              <HeaderLink label="Users" id="users" panel={panel} setPanel={setPanel} />
              <HeaderLink
                label="Groups"
                id="groups"
                panel={panel}
                setPanel={setPanel}
                disabled={!groupMessage || !isOrganizer}
              />
            </FlexGrid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <FlexGrid item xs={10}>
              <HeaderLink label="Messages" id="messages" panel={panel} setPanel={setPanel} />
              <HeaderLink label="Users" id="users" panel={panel} setPanel={setPanel} />
              <HeaderLink
                label="Groups"
                id="groups"
                panel={panel}
                setPanel={setPanel}
                disabled={!groupMessage || !isOrganizer}
              />
              {isOrganizer && <SettingTab label={<Settings />} id="settings" panel={panel} setPanel={setPanel} />}
            </FlexGrid>
            <Close item xs={2} textAlign="right">
              <CloseButton />
            </Close>
          </Grid>
        </>
      )}
    </Container>
  )
}
