import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { Header } from './Header'
import { FooterWrapper } from './UI/Footer'

import UpdateSettings from './graphql/mutations/updateSettings.gql'

const StyledLabel = styled(FormControlLabel)`
  text-transform: none;
`

const StyledButton = styled(Button)`
  text-transform: none;
`

export const Settings = ({ groupMessage, isOrganizer, settings, setSettings, panel, setPanel }) => {
  const [staffCanTextToColleagues, setStaffCanTextToColleagues] = useState(settings.staffCanTextToColleagues)

  const [updateSettings] = useMutation(UpdateSettings, {
    onCompleted: ({ updateOrganizer }) => {
      if (updateOrganizer.errors.length === 0) {
        setSettings(updateOrganizer.organizer.user.currentDepartment.inhouseOptions)
      }
    },
  })

  const handleSaveSettings = () => {
    updateSettings({
      variables: {
        inhouseOptions: {
          staffCanTextToColleagues,
        },
      },
    })
  }

  return (
    <>
      <div className="modal-header">
        <Header groupMessage={groupMessage} isOrganizer={isOrganizer} panel={panel} setPanel={setPanel} />
      </div>
      <div className="modal-body">
        <div>
          <StyledLabel
            control={(
              <Checkbox
                checked={staffCanTextToColleagues}
                onChange={({ target }) => setStaffCanTextToColleagues(target.checked)}
                color="primary"
              />
            )}
            label="Allow Employees to message colleagues in this department"
          />
        </div>
      </div>
      <div className="modal-footer">
        <FooterWrapper>
          <StyledButton variant="contained" color="primary" onClick={handleSaveSettings}>
            Save
          </StyledButton>
        </FooterWrapper>
      </div>
    </>
  )
}
