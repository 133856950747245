import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import { BackButton } from './BackButton'
import { CloseButton } from './CloseButton'
import { CHAT_ROOM_TYPE, MESSAGE_TABS } from '../../../lib/constants'
import { toDate } from '../../helpers/time'

const Label = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin: 0 5px;
`

const Close = styled(Grid)`
  text-align: 'right';
`

const TerminateText = styled.div(
  ({ theme }) => `
  font-size: 14px;
  color: ${theme.palette.error.main};
`,
)

const BodyThread = ({ type, isOrganizer, users }) => {
  const title = useMemo(() => {
    if (type === CHAT_ROOM_TYPE.DIRECT) {
      return users[0].fullName
    }
    if (type === CHAT_ROOM_TYPE.BROADCAST) {
      return 'Broadcast'
    }
    if (type === CHAT_ROOM_TYPE.GROUP) {
      return 'Group Message'
    }
    return ''
  }, [type, users])

  return (
    <>
      <div>{title}</div>
      {isOrganizer && (
        <div>
          {type === CHAT_ROOM_TYPE.DIRECT && users[0].staff && users[0].staff.terminatedAt && (
            <TerminateText>{`Terminated as of ${toDate(users[0].staff.terminatedAt)}`}</TerminateText>
          )}
        </div>
      )}
    </>
  )
}

export const Header = ({ setPanel, tab, name, type, isOrganizer, users, onClose }) => {
  const body = useMemo(() => {
    if (tab === MESSAGE_TABS.USERS) {
      return 'New Message'
    }
    if (tab === MESSAGE_TABS.THREAD) {
      return <BodyThread type={type} isOrganizer={isOrganizer} users={users} />
    }
    if (tab === MESSAGE_TABS.NEW_GROUP) {
      return 'Create Group'
    }
    if (tab === MESSAGE_TABS.GROUP_USER) {
      return name
    }
    return null
  }, [isOrganizer, name, tab, type, users])

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item md={1}>
        <BackButton setPanel={setPanel} />
      </Grid>
      <Grid item xs={6}>
        <Label>{body}</Label>
      </Grid>
      <Close item xs={5} textAlign="right">
        <CloseButton onClose={onClose} />
      </Close>
    </Grid>
  )
}
