import React from 'react'

import { NavToggle } from './NavToggle'

export default {
  title: 'Onboarding/Scheduling/NavToggle',
  component: NavToggle,
}

const Template = (args) => <NavToggle {...args} />
const dummyData = {
  data: {
    organizer: {
      organizerId: '3',
      userId: '7',
      permittedDepartments: [
        {
          id: '42',
          name: 'testunder',
          parentId: '41',
        },
        {
          id: '28',
          name: 'departmentA',
          parentId: '27',
        },
        {
          id: '29',
          name: 'Test Organizer2',
          parentId: null,
        },
        {
          id: '31',
          name: 'departmentF',
          parentId: '29',
        },
        {
          id: '30',
          name: 'departmentG',
          parentId: '29',
        },
        {
          id: '4',
          name: 'Test Company Name 2',
          parentId: '29',
        },
        {
          id: '23',
          name: 'tetst',
          parentId: '29',
        },
        {
          id: '24',
          name: 'test',
          parentId: '29',
        },
        {
          id: '41',
          name: 'test facility',
          parentId: null,
        },
        {
          id: '36',
          name: 'sadfasdf',
          parentId: '29',
        },
      ],
      partOfDepartments: [
        {
          id: '42',
          parentId: '41',
          name: 'testunder',
        },
        {
          id: '28',
          parentId: '27',
          name: 'departmentA',
        },
        {
          id: '29',
          parentId: null,
          name: 'Test Organizer2',
        },
        {
          id: '31',
          parentId: '29',
          name: 'departmentF',
        },
        {
          id: '30',
          parentId: '29',
          name: 'departmentG',
        },
        {
          id: '4',
          parentId: '29',
          name: 'Test Company Name 2',
        },
        {
          id: '23',
          parentId: '29',
          name: 'tetst',
        },
        {
          id: '24',
          parentId: '29',
          name: 'test',
        },
        {
          id: '41',
          parentId: null,
          name: 'test facility',
        },
        {
          id: '27',
          parentId: null,
          name: 'facilityA',
        },
        {
          id: '36',
          parentId: '29',
          name: 'sadfasdf',
        },
      ],
    },
  },
}

const handleChange = (value) => console.log(value)

export const Main = Template.bind({})
Main.args = {
  permitted: dummyData.data.organizer.permittedDepartments,
  partOf: dummyData.data.organizer.partOfDepartments,
  handleChange,
}

export const OneLevel = Template.bind({})
OneLevel.args = {
  permitted: dummyData.data.organizer.permittedDepartments.filter((x) => !x.parentId),
  partOf: dummyData.data.organizer.partOfDepartments.filter((x) => !x.parentId),
  handleChange,
}
