import dayjs from 'dayjs'
import { weekdayFor } from './timeFunctions'

export const shiftDefault = {
  position: { id: 1, name: 'General' },
  qty: 1,
  startAt: 540,
  endAt: 1020,
  duration: '8.0',
  breakDuration: 0,
  breakQty: 1,
  unpaid: false,
  dates: [dayjs().add(1, 'day')],
  recurring: false,
  notes: '',
  location: '',
  city: '',
  region: '',
  country: '',
  days: [weekdayFor(dayjs().add(1, 'day'))],
  endDate: dayjs().add(1, 'week')
    .add(2, 'day'),
}
