import React, { useState, useEffect } from 'react'
import { FormControl, Select, MenuItem, Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled'
import { humanTime, buildList } from './lib/timeFunctions'
import { Label } from './Label'

const GridStyled = styled(Grid)`
  margin-bottom: -16px;
`

const TimeStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Dropdown = styled(Select)`
  width: 90px;
  padding: 0px;
  div {
    padding: 8px;
  }
`

const DropdownQty = styled(Dropdown)`
  width: 75px;
`

//
// this component deals the logic in two different formats.
// everything that come from parent components and all the information
// submitted to parent components are in 'human time', for example '9:00',
// '18:45'.
//
// everything that is calculated locally is dealt in machine time.
// the number of minutes from midnight, for example, 9am is 540.
//
// this is for easy calculation of total time, time difference and support
// across day calculations without attaching the information to one specific day.
//
export const TimeDialog = ({
  startAt,
  startAtHandler,
  endAt,
  endAtHandler,
  breakDuration,
  breakDurationHandler,
  breakQty,
  breakQtyHandler,
  durationHandler,
  unpaid,
  unpaidHandler,
}) => {
  const [starts, setStarts] = useState([])
  const [ends, setEnds] = useState([])

  const startAtLocalHandler = ({ target }) => startAtHandler(target.value)
  const endAtLocalHandler = ({ target }) => endAtHandler(target.value)
  const breakDurationLocalHandler = ({ target }) => breakDurationHandler(target.value)
  const unpaidLocalHandle = ({ target }) => unpaidHandler(target.checked)
  const breakQtyLocalHandler = ({ target }) => breakQtyHandler(target.value)

  useEffect(() => {
    setStarts(
      buildList(0, 23 * 60 + 45, 15).map((timeInMinutes) => ({
        machine: timeInMinutes,
        human: humanTime(timeInMinutes),
      })),
    )
    setEnds(
      buildList(0, 23 * 60 + 45, 15).map((timeInMinutes) => ({
        machine: timeInMinutes,
        human: humanTime(timeInMinutes),
      })),
    )
  }, [])

  useEffect(() => {
    if (startAt >= 0 && endAt >= 0) {
      if (unpaid) {
        if (startAt > endAt) {
          durationHandler(((24 * 60 - startAt + endAt - breakDuration) / 60.0).toFixed(2))
        } else {
          durationHandler(((endAt - startAt - breakDuration) / 60.0).toFixed(2))
        }
      } else if (startAt > endAt) {
        durationHandler(((24 * 60 - startAt + endAt) / 60.0).toFixed(2))
      } else {
        durationHandler(((endAt - startAt) / 60.0).toFixed(2))
      }
    }
  }, [startAt, endAt, breakDuration, unpaid, durationHandler])

  return (
    <GridStyled container spacing={2}>
      <Grid item xs={6.5}>
        <Label className="timeLabel">Time</Label>
        <TimeStyled>
          <FormControl className="timeStartAt" variant="outlined">
            <Dropdown value={startAt} onChange={startAtLocalHandler}>
              {starts
                && starts.map((time) => (
                  <MenuItem key={`${time.machine}-startAt`} value={time.machine}>
                    {time.human}
                  </MenuItem>
                ))}
            </Dropdown>
          </FormControl>
          <FontAwesomeIcon className="arrow" icon={faArrowRight} />

          <FormControl className="timeEndAt" variant="outlined">
            <Dropdown value={endAt} onChange={endAtLocalHandler}>
              {ends
                && ends.map((time) => (
                  <MenuItem key={`${time.machine}-endAt`} value={time.machine}>
                    {time.human}
                  </MenuItem>
                ))}
            </Dropdown>
          </FormControl>
        </TimeStyled>
      </Grid>
      <Grid item xs={2.5}>
        <Label className="breakQtyLabel">Break Qty</Label>
        <FormControl className="break" variant="outlined">
          <DropdownQty value={breakQty} onChange={breakQtyLocalHandler}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </DropdownQty>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Label className="breakLabel">Break</Label>
        <FormControl className="break" variant="outlined">
          <Dropdown value={breakDuration} onChange={breakDurationLocalHandler}>
            <MenuItem value={0}>0 Min</MenuItem>
            <MenuItem value={15}>15 Min</MenuItem>
            <MenuItem value={30}>30 Min</MenuItem>
            <MenuItem value={45}>45 Min</MenuItem>
            <MenuItem value={60}>1 Hour</MenuItem>
          </Dropdown>
        </FormControl>
        <FormControlLabel
          label="Unpaid"
          control={(
            <Checkbox
              checked={unpaid}
              onChange={unpaidLocalHandle}
              sx={{
                color: '#637381',
                '&.Mui-checked': {
                  color: '#00848e',
                },
              }}
            />
          )}
        />
      </Grid>
    </GridStyled>
  )
}

//
