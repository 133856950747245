import React, { useState } from 'react'
import { LocationDialog } from './LocationDialog'

export default {
  title: 'ShiftModal/Dialog/Location',
  component: LocationDialog,
}

const Template = (args) => {
  const [location, locationHandler] = useState(args.location)
  return (
    <LocationDialog
      location={location}
      locationHandler={locationHandler}
    />
  )
}
export const Main = Template.bind({})

Main.args = {
  location: '7900 McLaughlin Rd, Mississauga, ON, L5B 5C7',
}
