import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
let host
let webHost
let socketHost
let sentryDsn
let splitClientKey

if (process.env.NODE_ENV === 'development') {
  host = 'http://localhost:4000'
  webHost = 'http://localhost:3000'
  socketHost = 'ws://'
  splitClientKey = process.env.SPLIT_CLIENT_KEY
} else if (process.env.NODE_ENV === 'staging') {
  host = 'https://staging.hyrestaff.com/infinity'
  webHost = 'https://staging.hyrestaff.com'
  socketHost = 'wss://'
  splitClientKey = process.env.SPLIT_CLIENT_KEY
} else if (process.env.NODE_ENV === 'production') {
  host = '/infinity'
  webHost = '/'
  socketHost = 'wss://'
  sentryDsn = 'https://718b7f9c6604436ab72d92db6e0f95ae@sentry.io/1855261'
  splitClientKey = process.env.SPLIT_CLIENT_KEY
}

export const CONSTANTS = {
  HOST: host,
  WEB_HOST: webHost,
  SENTRY_DSN: sentryDsn,
  SOCKET_HOST: socketHost,
  SPLIT_CLIENT_KEY: splitClientKey,
}

export const SHIFT_ENUMS = {
  TIMESHEET_STATUS_PENDING: 'PENDING',
  TIMESHEET_STATUS_FINALIZED: 'FINALIZED',
  WORK_STATUS_WORKED: 'WORKED',
  WORK_STATUS_LATE: 'LATE',
}

export const IN_HOUSE_SHIFTS_STATES = {
  CURRENT: 'current',
  UPCOMING: 'upcoming',
  PAST: 'past',
}

export const SHIFT_TYPE_ENUM = {
  HYRE: 'hyre',
  IN_HOUSE: 'in-house',
  AGENCY: 'agency',
}

export const CREATE_SHIFT_TYPE = {
  INHOUSE_SHIFT: 'INHOUSE',
  HYRE_SHIFT: 'HYRE',
  AGENCY_SHIFT: 'AGENCY',
}

export const ORDER_STATES = {
  PENDING_ORDERS: 'pending_orders',
  ACCEPTED_ORDERS: 'accepted_orders',
  DECLINED_ORDERS: 'declined_orders',
  DRAFT_ORDERS: 'draft_orders',
}

export const { GOOGLE_API_KEY } = process.env

export const EMAIL_STATUS = {
  NEW_EMAIL: 'New Email',
  VERIFIED: 'Verified',
  UNVERIFIED: 'Unverified',
  REMOVING: 'Removing',
}

export const HISTORY_STATE = {
  ALL: 'all',
  OUTSTANDING: 'outstanding',
  PENDING: 'pending',
  PAID: 'paid',
}

export const DEFAULT_CERT = {
  title: '',
  expiry: dayjs(new Date()).startOf('D')
    .utc(true)
    .toJSON(),
  picture: '',
  isDeleted: false,
  isSample: true,
}

export const DEFAULT_FORM = {
  title: '',
  file: '',
  isDeleted: false,
  isSample: true,
}

export const CHARGE_TYPE = {
  PERCENT: 'percent',
  AMOUNT: 'amount',
}

export const CHAT_ROOM_TYPE = {
  DIRECT: 'direct',
  BROADCAST: 'broadcast',
  GROUP: 'group_chat',
}

export const SOCKET_ACTION_TYPE = {
  DEFAULT: 'default',
  MESSAGE_SENT: 'message_sent',
  MESSAGE_READ: 'message_read',
}

export const MESSAGE_TYPE = {
  DIRECT: 'direct',
  SYSTEM: 'system',
}

export const MESSAGE_STATUS = {
  SENT: 'sent',
  RECEIVED: 'received',
  READ: 'read',
}

export const MESSAGE_TABS = {
  MESSAGES: 'messages',
  USERS: 'users',
  GROUPS: 'groups',
  THREAD: 'thread',
  SETTINGS: 'settings',
  NEW_GROUP: 'newGroups',
  GROUP_USER: 'groupUser',
  GROUP_SETTING: 'groupSetting',
}

export const REGEX_VALIDATION = {
  IS_LINK: /(https?:\/\/[^\s]+)/g,
  HAS_HEADER: new RegExp('([\\w+]+\\:\\/\\/)', 'g'),
}

export const zeroPad = (num, places) => num.toString().padStart(places, '0')

export const capitalize = (string, all) => {
  const str = string.toLowerCase()
  if (all) {
    return str
      .split(' ')
      .map((word) => capitalize(word))
      .join(' ')
  }
  return str.charAt(0).toUpperCase() + str.substring(1)
}

export function hexIsLight(color) {
  const hex = color.replace('#', '')
  const red = parseInt(hex.substring(0, 0 + 2), 16)
  const green = parseInt(hex.substring(2, 2 + 2), 16)
  const blue = parseInt(hex.substring(4, 4 + 2), 16)
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000
  return brightness > 155
}

export const GetInitialsName = (name) => {
  const allNames = name.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    let change = acc
    if (index === 0 || index === allNames.length - 1) {
      change = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return change
  }, '')
  return initials
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const inRange = (x, min, max) => ((x - min) * (x - max) <= 0)

export const filterTags = ({ array, search }) => {
  const searchText = search.toLowerCase()
  return array.filter((arr) => {
    const nameMatch = arr.name.toLowerCase().includes(searchText)
    const categoryMatch = arr.category && arr.category.toLowerCase().includes(searchText)
    const nullCateText = 'uncategorized'
    const unCategorizedMatch = arr.category === null && nullCateText.includes(searchText)
    return (nameMatch || categoryMatch) || unCategorizedMatch
  })
}
