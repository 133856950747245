import React from 'react'
import { Typography, Button } from '@material-ui/core'
import styled from '@emotion/styled'

const StyledWrapper = styled.div`
  margin: 25px 0;

  .days {
    display: block;

    .sub {
      margin-left: 10px;
      color: #828282;
      font-size: 10px;
    }
  }

  .daysSelection {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    button {
      width: 36px;
      min-width: 36px;
    }
  }
`

export const Rules = ({ days, daysHandler }) => {
  const dayVariant = (day) => (days.find((dayEnabled) => dayEnabled === day) ? 'contained' : 'outlined')

  const clickOn = (day) => () => {
    const enabled = days.find((dayEnabled) => dayEnabled === day)
    if (enabled) {
      daysHandler(
        days.filter((dayEnabled) => dayEnabled !== day),
      )
    } else {
      daysHandler([day, ...days])
    }
  }

  return (
    <StyledWrapper>
      <div className="days">
        <Typography display="inline">Recurring shift details</Typography>
        <Typography display="inline" variant="caption" className="sub">Select recurring days</Typography>
      </div>
      <div className="daysSelection">
        <Button variant={dayVariant('mon')} color="primary" onClick={clickOn('mon')}>M</Button>
        <Button variant={dayVariant('tue')} color="primary" onClick={clickOn('tue')}>T</Button>
        <Button variant={dayVariant('wed')} color="primary" onClick={clickOn('wed')}>W</Button>
        <Button variant={dayVariant('thu')} color="primary" onClick={clickOn('thu')}>T</Button>
        <Button variant={dayVariant('fri')} color="primary" onClick={clickOn('fri')}>F</Button>
        <Button variant={dayVariant('sat')} color="primary" onClick={clickOn('sat')}>S</Button>
        <Button variant={dayVariant('sun')} color="primary" onClick={clickOn('sun')}>S</Button>
      </div>
    </StyledWrapper>
  )
}
