/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react'
import {
  Autocomplete,
  Box,
  Chip,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  ListSubheader,
  Paper,
  Popper,
  TextField,
  Tooltip,
} from '@material-ui/core'
import styled from '@emotion/styled'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useDebounce } from 'use-debounce'
import { useLazyQuery } from '@apollo/client'
import { Pencil } from './Icons/Pencil'
import Search from './Icons/Search'

import GetLabels from './graphql/query/getLabels.gql'

const StyledTextField = styled(TextField)`
  input {
    padding: 7px 12px;
    font-size: 14px;
  }
  .MuiOutlinedInput-notchedOutline {
    color: #c4cdd5;
  }
  .MuiInputAdornment-root {
    .MuiTypography-root {
      font-size: 14px;
    }
  }
  .Mui-focused {
    border: none;
    :not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #00848e !important;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
      }
    }
  }
`

const Dropdown = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  flex-wrap: wrap;
  min-height: 39px;
  padding-right: 30px;
  position: relative;
  cursor: pointer;
  width: 100%;
`

const IconDown = styled(ArrowDropDownIcon)`
  position: absolute;
  right: 5px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
`

const Placeholder = styled.div`
  color: rgba(0, 0, 0, 0.4);
  padding-left: 7px;
`

const ChipCate = styled(Chip)(
  ({ maxwidth = '150px' }) => `
  max-width: ${maxwidth};
`,
)

const LabelPopper = styled(Popper)`
  z-index: 1400;
`

const Wrapper = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.15);
`

const DropdownBody = styled(Paper)`
  position: relative !important;
  transform: none;
  width: 300px !important;
  &.MuiAutocomplete-popper,
  .MuiAutocomplete-paper {
    box-shadow: none;
    transition: none;
    border-radius: 0;
  }
  .MuiAutocomplete-groupUl li {
    padding: 6px 16px;
  }
  .MuiAutocomplete-listbox {
    padding: 0;
    max-height: 35vh;
    ::-webkit-scrollbar {
      width: 20px;
      height: 18px;
    }
    ::-webkit-scrollbar-thumb {
      height: 6px;
      width: 12px;
      border: 5px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 15px;
      background-color: #dddddd;
      -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
    .MuiListSubheader-root {
      background-color: #fff;
    }
  }
`

const AutoLabels = styled(Autocomplete)`
  padding: 15px 12px 0 12px;
`

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
`

const FlexDiv = styled.div`
  border-top: 1px solid #c4cdd5;
  padding: 0 19px;
  display: flex;
  align-items: center;
  color: #212b36;
  justify-content: space-between;
`

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group ? params.group : <i>Uncategorized</i>}
  </ListSubheader>,
  params.children,
]

const filterLabels = (labels, search) => {
  const searchText = search.toLowerCase()
  return labels.filter((label) => {
    const nameMatch = label.name.toLowerCase().includes(searchText)
    const categoryMatch = label.category && label.category.toLowerCase().includes(searchText)
    const nullCateText = 'uncategorized'
    const unCategorizedMatch = label.category === null && nullCateText.includes(searchText)
    return (nameMatch || categoryMatch) || unCategorizedMatch
  })
}

export const LabelDropdown = ({ labels, setLabels }) => {
  const [defaultOptions, setDefaultOptions] = useState([])
  const [options, setOptions] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [search] = useDebounce(searchText, 500)

  const onClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const [getLabels, { loading }] = useLazyQuery(GetLabels, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ labels: res }) => {
      setDefaultOptions(res)
      setOptions(res)
    },
  })

  useEffect(() => {
    if (anchorEl) {
      getLabels()
    }
  }, [anchorEl, getLabels])

  useEffect(() => {
    setOptions(filterLabels(defaultOptions, search))
  }, [defaultOptions, search])

  const title = useMemo(() => 'Select a label to assign', [])

  const onClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
    setSearchText('')
  }

  const onDelete = (id) => {
    setLabels(labels.filter((label) => label.id !== id))
  }

  const onEdit = () => {
    window.open('/organizer/shift-labels', '_blank')
  }

  return (
    <div>
      <Dropdown component="div" onClick={onClick}>
        {labels.length > 0 ? (
          labels.map((label) => (
            <Tooltip key={label.id} title={label.name}>
              <ChipCate label={label.name} onDelete={() => onDelete(label.id)} />
            </Tooltip>
          ))
        ) : (
          <Placeholder>{title}</Placeholder>
        )}
        <IconDown />
      </Dropdown>
      <LabelPopper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={onClose}>
          <Wrapper>
            <AutoLabels
              open
              fullWidth
              multiple
              PopperComponent={DropdownBody}
              value={labels}
              onChange={(event, newValue) => {
                setLabels(newValue)
              }}
              inputValue={searchText}
              onInputChange={(_event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText(value)
                }
              }}
              disableCloseOnSelect
              disablePortal
              loading={loading}
              renderLabel={() => null}
              options={options}
              filterOptions={(opts) => opts}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, val) => option.name === val.name}
              groupBy={(option) => option.category}
              renderGroup={renderGroup}
              renderOption={(props, option) => (
                <li {...props}>
                  <DropdownItem>
                    <Tooltip title={option.name} placement="right">
                      <div>{option.name}</div>
                    </Tooltip>
                  </DropdownItem>
                </li>
              )}
              renderInput={(params) => (
                <StyledTextField
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder="Search"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  autoFocus
                />
              )}
            />
            <FlexDiv>
              <div>Manage Labels</div>
              <IconButton onClick={onEdit}>
                <Pencil />
              </IconButton>
            </FlexDiv>
          </Wrapper>
        </ClickAwayListener>
      </LabelPopper>
    </div>
  )
}
