import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { FormControlLabel, Button, Checkbox } from '@material-ui/core'
import styled from '@emotion/styled'
import { FooterWrapper } from './UI/Footer'
import { MESSAGE_TABS } from '../../lib/constants'
import { Header } from './UI/Header'

import GetGroupSetting from './graphql/queries/getGroupSetting.gql'
import UpdateGroupSetting from './graphql/mutations/updateGroupSetting.gql'

const StyledLabel = styled(FormControlLabel)`
  text-transform: none;
`

const StyledButton = styled(Button)`
  text-transform: none;
`

export const GroupSetting = ({ setPanel, thread, groupName, setNotifyMsg }) => {
  const [allowSendMessage, setAllowSendMessage] = useState(false)

  useQuery(GetGroupSetting, {
    variables: {
      roomId: thread.roomId,
    },
    onCompleted: ({ currentUser }) => {
      const { items } = currentUser.availableRooms
      setAllowSendMessage(items[0].allowSendMessage)
    },
  })

  const [updateSettings] = useMutation(UpdateGroupSetting, {
    onCompleted: ({ chatAccessControl }) => {
      if (chatAccessControl.errors) {
        setNotifyMsg({
          message: chatAccessControl.errors[0].message,
          type: 'error',
        })
      }
    },
  })

  const handleSaveSettings = () => {
    updateSettings({
      variables: {
        roomId: thread.roomId,
        allowSendMessage,
      },
    })
  }

  const handleBack = () => {
    setPanel(MESSAGE_TABS.GROUPS)
  }

  return (
    <>
      <div className="modal-header">
        <Header
          tab={MESSAGE_TABS.GROUP_USER}
          name={groupName}
          setPanel={handleBack}
        />
      </div>
      <div className="modal-body">
        <div>
          <StyledLabel
            control={(
              <Checkbox
                checked={allowSendMessage}
                onChange={({ target }) => setAllowSendMessage(target.checked)}
                color="primary"
              />
            )}
            label="Allow staff to send messages to the group"
          />
        </div>
      </div>
      <div className="modal-footer">
        <FooterWrapper>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleSaveSettings}
          >
            Save
          </StyledButton>
        </FooterWrapper>
      </div>
    </>
  )
}
