import React, { useMemo, useState } from 'react'
import { Select, FormControl, MenuItem, InputAdornment } from '@material-ui/core'
import styled from '@emotion/styled'

import { useLazyQuery } from '@apollo/client'
import GET_AGENCY_SERVICE_CHARGE from './query/serviceCharges.gql'
import { formattedCurrency, formattedCurrencyTransaction } from '../helpers/currency'
import { CHARGE_TYPE } from '../../lib/constants'
import { Field } from './Field'

const Container = styled.div(
  ({ theme }) => `
  margin-top: 10px;
  display: grid;
  grid-template-columns: 160px 120px auto auto;
  ${theme.breakpoints.down('sm')} {
    grid-template-columns: 125px 80px auto auto;
  }
  grid-template-rows: auto auto;
  column-gap: 10px;
  row-gap: 5px;
  grid-template-areas:
    'agencyLabel billRateLabel estimatedLabel serviceLabel'
    'agency      billRate      estimated      service';
`,
)
const AgencySelect = styled(Select)(
  ({ theme }) => `
  width: 150px;
  padding: 0px;
  div {
    padding: 8px;
  }
  ${theme.breakpoints.down('sm')} {
    width: 125px;
  }
`,
)

const Estimated = styled.div`
  grid-area: estimated;
  align-self: center;
`

const Service = styled.div`
  grid-area: service;
  align-self: center;
`

export const Cost = ({ handler, shift, shiftIndex, position, partnerships, duration }) => {
  const { agency, qty } = shift
  const [serviceCharges, setServiceCharges] = useState([])
  const [minWage, setMinWage] = useState(0)
  const [billedWage, setBilledWage] = useState(0)

  const compare = (aplOne, aplTwo) => parseInt(aplOne.id, 10) === parseInt(aplTwo.id, 10)

  const [getAgencyServiceCharges] = useLazyQuery(GET_AGENCY_SERVICE_CHARGE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ departmentServiceCharges }) => {
      setServiceCharges(departmentServiceCharges)
    },
  })

  const update = ({ target }) => {
    getAgencyServiceCharges({
      variables: {
        departmentId: target.value,
        includeDefault: true,
      },
    })
    const partner = partnerships.find((possiblePartnership) => possiblePartnership.id === target.value)
    const { wage } = partner
      .departmentPositions.find((departmentPosition) => compare(departmentPosition.adminPositionList, position))
    setMinWage(wage)
    setBilledWage(wage)
    handler({ ...shift, agency: target.value, wage }, shiftIndex)
  }

  const updateWage = ({ target }) => {
    if (target.value >= minWage) {
      setBilledWage(target.value)
      handler({ ...shift, wage: target.value }, shiftIndex)
    } else {
      setBilledWage(minWage)
      handler({ ...shift, wage: minWage }, shiftIndex)
    }
  }

  const possible = useMemo(
    () => partnerships.filter((partnership) => {
      const positions = partnership.departmentPositions.map(
        (departmentPosition) => departmentPosition.adminPositionList,
      )

      return positions.filter((possiblePosition) => compare(possiblePosition, position)).length > 0
    }),
    [partnerships, position],
  )

  const totalWage = useMemo(() => (billedWage * qty * duration).toFixed(2), [billedWage, qty, duration])

  const chargeCost = useMemo(() => {
    let cost = 0
    if (serviceCharges.length > 0) {
      serviceCharges.map((charge) => {
        if (charge.chargeType === CHARGE_TYPE.PERCENT) {
          cost += (totalWage * charge.amount) / 100
        } else {
          cost += charge.amount
        }
        return cost
      })
    }
    return cost
  }, [serviceCharges, totalWage])

  return (
    <Container>
      <div className="agencyLabel">Select Agency</div>
      <div className="billedRateLabel">Set Billed Rate</div>
      <div className="estimatedLabel">Est. Cost</div>
      <div className="serviceLabel">Agency&apos;s Service Charge</div>
      <div className="agency">
        <FormControl variant="outlined">
          <AgencySelect onChange={update}>
            {possible.map((possiblePartnership) => (
              <MenuItem key={possiblePartnership.id} value={possiblePartnership.id}>
                {`${possiblePartnership.agency.name}`}
              </MenuItem>
            ))}
          </AgencySelect>
        </FormControl>
      </div>
      <div className="billedRate">
        {agency && (
          <FormControl variant="outlined">
            <Field
              type="number"
              value={billedWage}
              onChange={({ target }) => setBilledWage(target.value)}
              onBlur={updateWage}
              inputProps={{
                min: minWage,
                step: 0.5,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </FormControl>
        )}
      </div>
      <Estimated>{billedWage && formattedCurrencyTransaction(totalWage)}</Estimated>
      <Service>{formattedCurrency(chargeCost)}</Service>
    </Container>
  )
}
