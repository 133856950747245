export const humanTime = (inMinutes) => {
  let inHour = Math.floor(inMinutes / 60)
  const minConv = inMinutes % 60

  if (inHour >= 24) {
    inHour -= 24
  }

  const hourString = inHour.toString().padStart(2, 0)
  const minString = minConv.toString().padStart(2, 0)

  return `${hourString}:${minString}`
}

export const machineTime = (inString) => {
  const [hour, minutes] = inString.split(':').map((part) => parseInt(part, 10))

  return (hour * 60) + minutes
}

//
// Receive the time in minutes and increment + minutes
//
const increment = (from, interval) => from + interval

//
// Given the initial time in minutes from midnight (midnight = 0) calculate every possible
// step respecting the interval and incrementing the offset.
//
export const buildList = (from, to, interval, offset = 0) => {
  const times = []

  for (let time = increment(from, offset); time <= to; time = increment(time, interval)) {
    times.push(time)
  }

  return times
}

//
//
//
export const weekdayFor = (date) => new Intl
  .DateTimeFormat(
    'en-US', {
      weekday: 'short',
    },
  )
  .format(date)
  .toLowerCase()
