import React, { useMemo } from 'react'
import { Checkbox, Grid } from '@material-ui/core'
import styled from '@emotion/styled'
import { Avatar } from '../components/Avatar'

const Container = styled(Grid)`
  margin: 8px 0;
`

export const User = ({ user, editing, selected, setSelected }) => {
  const hasChecked = useMemo(() => !!selected.find((u) => u.id === user.id), [selected, user])

  const handleSelect = () => {
    const list = [...selected]
    const index = list.findIndex((u) => u.id === user.id)
    if (index >= 0) {
      list.splice(index, 1)
    } else {
      list.push(user)
    }
    setSelected(list)
  }

  return (
    <Container container alignItems="center">
      <Grid item xs={10} container alignItems="center">
        <Grid item xs={2}>
          <Avatar thumbnail={user.avatar.thumb} />
        </Grid>
        <Grid item xs={10}>
          {user.fullName}
        </Grid>
      </Grid>
      <Grid item xs={2}>
        {editing && <Checkbox checked={hasChecked} onChange={handleSelect} color="primary" />}
      </Grid>
    </Container>
  )
}
