import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Grid, FormControlLabel, Checkbox, Divider } from '@material-ui/core'
import { useLazyQuery, useQuery } from '@apollo/client'
import { FilterDropdown } from '../components/FilterDropdown'
import { SearchBar } from './SearchBar'

import GetDepartmentInfo from '../graphql/queries/getDepartmentInfo.gql'
import GetMembers from '../graphql/queries/getUsers.gql'
import { TagDropdown } from '../../TagDropdown'

const StyledGrid = styled(Grid)`
  margin-bottom: 15px;
  position: sticky;
  top: -10px;
  background: #fff;
  z-index: 1;
`

const FilterGrid = styled(Grid)`
  margin-left: 15px;
`

const Separate = styled(Divider)`
  margin: 1em 0;
`

const FilterLabel = styled.div`
  color: #637381;
  font-weight: 600;
`

const SelectGroupWrapper = styled.div`
  display: flex;
`

const StyledText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .name {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .subText {
    font-size: 8px;
    color: #212B36;
  }
`

const StyledTagDropdown = styled(TagDropdown)`
  .dropdown {
    background-color: #F4F6F8;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .placeholder {
    color: #000;
  }
`

const DefaultVariables = {
  search: '',
  selectedDepartmentIds: [],
  adminPositionListIds: [],
  chatRoomIds: [],
  tagIds: [],
}

export const SearchUser = ({
  showTagManagement,
  departmentId,
  organizationId,
  isOrganizer,
  selectAll,
  handleSelect,
  setMembers,
  settings,
}) => {
  const [variables, setVariables] = useState(DefaultVariables)
  const [filterOptions, setFilterOptions] = useState([])
  const [groups, setGroups] = useState([])
  const [mounted, setMounted] = useState(!isOrganizer)

  useQuery(GetDepartmentInfo, {
    variables: {
      organizationId,
      departmentId,
      roomTypes: ['GROUP_CHAT'],
    },
    skip: !isOrganizer,
    onCompleted: ({ currentUser }) => {
      const { isFacility, adminPositionLists, childrenDepartments } = currentUser.currentDepartment
      const { availableRooms } = currentUser
      const options = []
      if (adminPositionLists.length > 0) {
        options.push({
          name: 'positions',
          options: adminPositionLists,
          selectedType: 'id',
          optionText: 'name',
          dataType: 'adminPositionListIds',
          checkAllText: 'All Positions',
          showName: true,
        })
      }
      if (isFacility && childrenDepartments.length > 0) {
        options.push({
          name: 'departments',
          options: childrenDepartments,
          selectedType: 'id',
          optionText: 'name',
          dataType: 'selectedDepartmentIds',
          checkAllText: 'All Departments',
          showName: true,
        })
      }
      setGroups(availableRooms.items)
      setVariables((prev) => ({
        ...prev,
        adminPositionListIds: adminPositionLists.map((pos) => pos.id),
        selectedDepartmentIds: isFacility ? childrenDepartments.map((dep) => dep.id) : [],
        chatRoomIds: [],
      }))
      setFilterOptions(options)
      setMounted(true)
    },
  })

  const [getMembers] = useLazyQuery(GetMembers, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ currentUser }) => {
      setMembers(currentUser.usersAbleToChat)
    },
  })

  const handleChangeSearch = (val) => {
    setVariables((prev) => ({ ...prev, page: 1, search: val }))
  }

  const handleOnChangeFilter = (listValue, key) => (e) => {
    let val = null
    if (e.target.value.includes('all')) {
      if (variables[key].toString() === listValue.toString()) {
        val = []
      } else {
        val = listValue
      }
    } else {
      val = e.target.value
    }
    setVariables((prev) => ({ ...prev, page: 1, [key]: val }))
  }

  const handleSelectGroups = ({ id, checked }) => {
    let listGroupIds = variables.chatRoomIds
    if (checked) {
      listGroupIds.push(id)
    } else {
      listGroupIds = listGroupIds.filter((groupId) => groupId !== id)
    }
    setVariables((prev) => ({ ...prev, chatRoomIds: listGroupIds }))
  }

  const handleSelectTags = (tagIds) => {
    setVariables((prev) => ({ ...prev, page: 1, limit: 20, tagIds }))
  }

  useEffect(() => {
    if (mounted) {
      const { search, adminPositionListIds, selectedDepartmentIds, tagIds, chatRoomIds } = variables
      getMembers({
        variables: {
          organizationId,
          departmentId,
          search,
          adminPositionListIds: adminPositionListIds.length === 0 ? null : adminPositionListIds,
          selectedDepartmentIds: selectedDepartmentIds.length === 0 ? null : selectedDepartmentIds,
          chatRoomIds: chatRoomIds.length === 0 ? null : chatRoomIds,
          tagIds: tagIds.length === 0 ? null : tagIds.map((tag) => tag.id),
        },
      })
    }
  }, [variables, mounted, organizationId, departmentId, getMembers, settings])

  return (
    <StyledGrid container alignItems="center">
      {isOrganizer && (
        <FilterGrid item container xs={9}>
          <Grid item xs={6}>
            <FilterDropdown title="Select a Group">
              <FilterLabel>
                YOUR GROUPS
              </FilterLabel>
              {groups.length > 0
                && groups.map((group) => (
                  <SelectGroupWrapper key={group.id}>
                    <Checkbox
                      color="primary"
                      checked={variables.chatRoomIds.includes(group.id)}
                      onChange={({ target }) => handleSelectGroups({ id: group.id, checked: target.checked })}
                    />
                    <StyledText>
                      <div className="name">
                        {group.name}
                      </div>
                      <div className="subText">
                        {`${group.chatParticipants.length} users`}
                      </div>
                    </StyledText>
                  </SelectGroupWrapper>
                ))}
            </FilterDropdown>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <FilterDropdown
              idBtn="filterButton"
              idMenu="filterDropdown"
              options={filterOptions}
              value={variables}
              handleOnChangeFilter={handleOnChangeFilter}
            >
              {showTagManagement && (
                <div>
                  <Separate />
                  <FilterLabel>TAGS</FilterLabel>
                  <StyledTagDropdown tags={variables.tagIds} setTags={handleSelectTags} placeholder="Select a Tag" />
                </div>
              )}
            </FilterDropdown>
          </Grid>
        </FilterGrid>
      )}
      <Grid item xs={isOrganizer ? 9 : 12}>
        <SearchBar search={variables.search} setSearch={handleChangeSearch} placeholder="Search Users" />
      </Grid>
      {isOrganizer && (
        <Grid item xs={3} textAlign="right">
          <FormControlLabel
            control={(
              <Checkbox
                checked={selectAll || false}
                onChange={handleSelect}
                name="selectAll"
                color="primary"
              />
            )}
            label="All"
          />
        </Grid>
      )}
    </StyledGrid>
  )
}
