import React from 'react'

export const Pencil = ({ className, width = 15, height = 15, color = '#637381' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M14.7563 3.36828C15.0812 3.04333 15.0812 2.50174 14.7563 2.19345L12.8066 0.243716C12.4983 -0.0812387
        11.9567 -0.0812387 11.6317 0.243716L10.0986 1.7685L13.2232 4.89307L14.7563 3.36828ZM0
        11.8754V15H3.12457L12.34 5.77628L9.21539 2.65171L0 11.8754Z`}
      fill={color}
    />
  </svg>
)
