import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import RecurringOff from '../Icons/RecurringOff'
import RecurringOn from '../Icons/RecurringOn'

const GreenButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  min-width: 0;
  padding: 7px;
  &.active {
    background-color: #00848e;
  }
`

const StyledWrapper = styled.div`
  align-self: flex-end;
  .radioContainer {
    display: inline;
    margin-left: 15px;
  }
`

export const RecurringOptions = ({ recurring, recurringHandler }) => {
  const [recur, setRecur] = useState(recurring)
  useEffect(() => {
    recurringHandler(recur)
  }, [recur, recurringHandler])

  useEffect(() => {
    setRecur(recurring)
  }, [recurring])

  return (
    <StyledWrapper>
      <div>
        <GreenButton
          color="primary"
          title={recur ? 'Turn off recurring' : 'Turn on recurring'}
          className={recur ? 'active' : ''}
          onClick={() => setRecur(!recur)}
        >
          {recur ? <RecurringOff fill="#FFF" /> : <RecurringOn fill="#637381" />}
        </GreenButton>
      </div>
    </StyledWrapper>
  )
}
