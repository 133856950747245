import React from 'react'
import TextField from '@material-ui/core/TextField'
import styled from '@emotion/styled'

const StyledTextField = styled(TextField)`
  input {
    padding: 8px;
  }
`

export const Field = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <StyledTextField variant="outlined" {...props} />
)
