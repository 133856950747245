import { ApolloClient, InMemoryCache, gql } from '@apollo/client'

export const CurrentUserHelper = ({ token, setUser }) => {
  const user = {}
  const client = new ApolloClient({
    uri: '/apisite/v1/graphql',
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  client
    .query({
      query: gql`
  {
    currentUser {
      id
      organizer {
        organizerId
      }
    }
  }
  `,
    })
    .then((result) => {
      setUser(result.data.currentUser)
    })

  return user
}
