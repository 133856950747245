import dayjs from 'dayjs'

const MONDAY_WEEKDAY_OFFSET = 1 // Sunday is first
const SUNDAY_WEEKDAY = 6
const FORMAT_TIME = 'HH:mm'
const FORMAT_DATE = 'YYYY-MM-DD'
const FORMAT_HUMAN_DATE = 'MMMM Do'
const FORMAT_SHORT_HUMAN_DATE = 'ddd, MMM D'
const FORMAT_DAY_OF_WEEK = 'dddd'
const FORMAT_SHORT_DATE = 'MMM D, YYYY'
const FORMAT_ISO8601 = 'YYYY-MM-DDTHH:mm:ss'
const FORMAT_HOUR = 'H'
const FORMAT_DATEPICKER_TIME = 'MM/DD/YYYY'
const FORMAT_ALL_DATA = 'MMM D, YYYY HH:mm'
const FORMAT_DAY_AND_MONTH = 'MMM DD'

const ensureDay = (day) => (typeof day === 'string' ? dayjs(day) : day)

export const weekday = (day) => {
  const offset = day.day()
  if (offset === 0) return SUNDAY_WEEKDAY

  return offset - MONDAY_WEEKDAY_OFFSET
}

export const roundToIntervalMinutes = (day, interval) => {
  const diffSeconds = day.unix() % (interval * 60)
  if (diffSeconds < 60) return day

  return day.add((interval * 60) - diffSeconds, 'seconds')
}

export const startOfWeek = (day) => {
  let adjustedDay = day || dayjs()

  if (weekday(adjustedDay) === SUNDAY_WEEKDAY) {
    adjustedDay = adjustedDay.subtract(1, 'week')
  }

  return adjustedDay.startOf('week').add(MONDAY_WEEKDAY_OFFSET, 'day')
}

export const intersect = (from1, to1, from2, to2) => (
  (from1.isAfter(from2) && from1.isBefore(to2))
    || (to1.isAfter(from2) && to1.isBefore(to2))
    || ((from1.isBefore(from2) || from1.isSame(from2)) && (to1.isAfter(to2) || to1.isSame(to2)))
)

export const changeDate = (day, formattedDate) => {
  const [year, month, date] = formattedDate.split('-')
  return dayjs(day).year(year)
    .month(month - 1)
    .date(date)
}

export const sameDay = (day1, day2) => dayjs(day1).startOf('day')
  .isSame(dayjs(day2).startOf('day'))
export const beforeDay = (day1, day2) => dayjs(day1).startOf('day')
  .isBefore(dayjs(day2).startOf('day'))
export const beginningOfNextDay = (day) => day.endOf('day').add(1, 'millisecond')

export const toTime = (day) => ensureDay(day).format(FORMAT_TIME)
export const toDate = (day) => ensureDay(day).format(FORMAT_DATE)
export const toHour = (day) => ensureDay(day).format(FORMAT_HOUR)
export const toDayOfWeek = (day) => ensureDay(day).format(FORMAT_DAY_OF_WEEK)
export const toHumanDate = (day) => ensureDay(day).format(FORMAT_HUMAN_DATE)
export const toShortHumanDate = (day) => ensureDay(day).format(FORMAT_SHORT_HUMAN_DATE)
export const toShortDate = (day) => ensureDay(day).format(FORMAT_SHORT_DATE)
export const toIso8601 = (day) => ensureDay(day).format(FORMAT_ISO8601)
export const toDatePicker = (day) => ensureDay(day).format(FORMAT_DATEPICKER_TIME)
export const toAllData = (day) => ensureDay(day).format(FORMAT_ALL_DATA)
export const toDayAndMonth = (day) => ensureDay(day).format(FORMAT_DAY_AND_MONTH)
