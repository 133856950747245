import React, { useMemo } from 'react'
import { Button, Grid } from '@material-ui/core'
import styled from '@emotion/styled'

const Container = styled(Grid)`
  button {
    text-transform: none;
    font-weight: 500;
    font-size: 15px;
  }

  .draft {
    background-color: #fff;
  }
`

export const Actions = ({ shakeShift, draftHandler, postHandler, enabled }) => {
  const isDisabled = useMemo(() => !enabled, [enabled])

  return (
    <Container container>
      <Grid item xs={4} sm={6}>
        <Button onClick={shakeShift} variant="contained">
          Add Shift
        </Button>
      </Grid>
      <Grid item xs={4} sm={3} textAlign="right">
        <Button className="draft" onClick={draftHandler} variant="outlined" disabled={isDisabled}>
          Save as Draft
        </Button>
      </Grid>
      <Grid item xs={4} sm={3} textAlign="right">
        <Button onClick={postHandler} variant="contained" disabled={isDisabled}>
          Post Shift(s)
        </Button>
      </Grid>
    </Container>
  )
}
